import React from 'react'
import Nav from '../../../UI/Nav'
import Footer from '../../../UI/Footer'
import { Row, Col } from 'react-bootstrap'
import { OurClient } from '../../Components'
// import { OurWorkComponent } from '../../../Components'
import { Feature } from './Components'
// import overview3 from "./Item"
// import all from "../../../AllImages"

import {
    Banner,
    Container,
    SectionTitle,
    // SectionDesc,
    // Heading,
    // heading1,
    Description,
    // LearnMore,
    // BgBlue,
} from '../../../Style'


import {
    arrowDown,
    // BlueDots,
    OurWorkVector,
    // heartbeat,
    // carribean,
    // ourFamilyDollar,
    // ourGlobal,
    // ourLsu,
    // astrazeneca,
    // eVisicLogo,
    // blueBgBox,
    // eVisicThumbnail,
    // eVisicThumbnail1,
    // eVisicThumbnail2,
    // pinkBgBox,
    // purpleBgBox,
    // mblProjectDemo,
    // mblProjectDemo1,
    mobile_multi,
    laptopimg,
    projectNow,
    projectNowN,
    // logoPlaceholder1,
    // logoPlaceholder2,
    // logoPlaceholder3,
} from '../../../AllImages'

const ProjectOverViewNewNow = ({
    heading_login,
    discription,
    heading_login1,
    discription1,
    heading_login2,
    discription2,
    heading_login3,
    discription3,
    heading_login4,
    discription4,
    heading_login5,
    discription5,
    heading_login6,
    discription6,
    heading_login7,
    discription7,
    heading_login8,
    discription8,
    heading_login9,
    discription9,
    heading_login10,
    discription10,
    heading_login11,
    discription11,
    heading_login12,
    discription12,
    heading_login13,
    discription13,
    heading_login14,
    discription14,
}) => {

    const overview = [
        // {
        //     "classN": "bg-none-style setting_bor setting_bor_1",
        //     "headingsection": "About Client",
        //     "description": "Tata Power Limited is an Indian electric utility company based in Mumbai, Maharashtra, India, and is part of the Tata Group founded in 1919. The company's core business is to generate, transmit, and distribute electricity. Tata Power is India's largest integrated power company with an installed electricity generation capacity of 10,577 MW. Currently, more than 10000 employees are actively working in the organization.",
        // },
        {
            "classN": "bg-none-style setting_bor setting_bor_1",
            "headingsection": "Client Requirements",
            "description": "The FOODINI application wants to bring a revolution and change in the restaurant business by providing an easy, reliable, and efficient transport system. Discovering the time-consuming process of ordering a meal at a restaurant or waiting in long queues or withinside did not get a great deal even after wasting time, so to resolve this form of affair the idea of creating an application that has a friendly interface and customer concept which makes it quick to take away and dine-in and use this utility. The decision to make the application and utility, FOODINI, is a flawlessly intelligent idea. So, they approached Hyperlink Infosystem with the concept, and we changed the concept to a fact successfully."
        },
    ]

    const overview3 = [
        {
            headingsection: "Dashboard:",
            description: "• Admin has a dashboard that contains different chart wise data, so it is easy to get data analysis. You can find Users, Ratio, Sale, Active users with months, Registration of users, KYC status, and redemption data in a chart manner.",
            headingsection1: "User:",
            description1: "• Admin can have all user's details with filtration options. Admin can export all user data with its filter. Admin can add, edit, and view user details. There is also a search feature to help in user searching.",
        },
    ]

    const overview4 = [
        {
            headingsection: "Dashboard:",
            description: "• Admin has a dashboard that contains different chart wise data, so it is easy to get data analysis. You can find Users, Ratio, Sale, Active users with months, Registration of users, KYC status, and redemption data in a chart manner.",
            headingsection1: "User:",
            description1: "• Admin can have all user's details with filtration options. Admin can export all user data with its filter. Admin can add, edit, and view user details. There is also a search feature to help in user searching.",
        },
    ]
    const overview5 = [
        {
            headingsection: "Dashboard:",
            description: "• Admin has a dashboard that contains different chart wise data, so it is easy to get data analysis. You can find Users, Ratio, Sale, Active users with months, Registration of users, KYC status, and redemption data in a chart manner.",
            headingsection1: "User:",
            description1: "• Admin can have all user's details with filtration options. Admin can export all user data with its filter. Admin can add, edit, and view user details. There is also a search feature to help in user searching.",
        },
    ]
    const overview6 = [
        {
            headingsection: "Dashboard:",
            description: "• Admin has a dashboard that contains different chart wise data, so it is easy to get data analysis. You can find Users, Ratio, Sale, Active users with months, Registration of users, KYC status, and redemption data in a chart manner.",
            headingsection1: "User:",
            description1: "• Admin can have all user's details with filtration options. Admin can export all user data with its filter. Admin can add, edit, and view user details. There is also a search feature to help in user searching.",
        },
    ]



    return (
        <>
            <Banner>
                <Container>
                    <Nav />
                    <Row className="align-items-center">
                        <Col md={6}>
                            <h1 className="mb-2">Foodini</h1>
                            <h1 className="mb-2">- Meal Ordering App</h1>
                            <p>There was a time when every person who desired to have scrumptious meals at their home had to visit the place in person, get their meals packed, and bring it along with them. This is all very old-school and time-consuming. So to make everything at ease, we present to you "FOODINI". Foodini is a meal ordering application and with its aid, users can order food anywhere and anytime, as per their requirements. Foodini has a user-friendly interface, is convenient to use, and makes it easy to reserve meals. It provides food as per requirements and provides takeaway and dine-in services. In food ordering services, the driving force supplies the customers' preferred restaurants. In the usage of takeaway services, the FOODINI users can carry their meals from the place where they give their order. The dine-in service is totally friendly for all the FOODINI users who need to visit the places for breakfast, lunch, and dinner. Through the use of dine-in service, the users can make reservations with specifications and their orders. It also accepts all the most widely acclaimed cards for making payments. It provides an employment rush for anyone who wants to generate extra income by delivering orders.</p>
                        </Col>
                        <Col className="text-center-md">
                            <OurWorkVector className="img-fluid mt-4 px-md-0 px-2" />
                        </Col>
                    </Row>
                </Container>
            </Banner>
            <div className="center">
                <img src={arrowDown} alt="icon" className="arrow-icon" />
            </div>
            <Container>
                <SectionTitle>Features</SectionTitle>
                <Description className="text-center">Tata Power - Skill Development Institute App Features.</Description>
                {overview.map(({ headingsection, description, classN }) => (
                    <>
                        <OurClient
                            classN={classN}
                            heading1={headingsection}
                            description={description}
                            logo1={projectNow}
                        />
                    </>
                ))}
            </Container>
            <Container>
                <SectionTitle>Features & (Customers App)</SectionTitle>


                {overview3.map((feature, img_change) => {
                    return (
                        <>
                            <Feature {...feature}
                                img_change={projectNowN}
                                classN="justify-content-sm-center"
                                heading_login="Signup / Login"
                                discription="- Users can log in using their email address, phone number, and social media accounts. - A user profile will be created from the tab 'Create Account.' When an account is created for the first time, customers must input the area to retrieve information about that area."
                                heading_login1="Home"
                                discription1="- On tapping the notification bell icon, the user can see the notifications, and through tapping on the cart icon, the user can flow to the cart screen.
                            - Here the users can place the location or change it as per their convenience.
                            - Using the search bar, the user can seek numerous dishes and places, and later can clear out the history too.
                            - Below, the customers can see the featured places and dishes that are trending near their location."
                                heading_login2="Restaurant Details"
                                discription2="- At the very top, the user can see the image of the restaurant along with its details, such as name, address, and the type of service it provides.
                            - Below is the user can see the payment method and other details.
                            - Searching, scrolling, and filtering the dishes is possible by the type of food.
                            - Tapping on the dish will redirect to food details that can later be added to the cart by the user.
                            - Tapping the '+' button can add the quantity of the dish.
                            Pushing the tab 'See your order', the user is redirected to the cart screen."
                                heading_login3="My Cart"
                                discription3="- On this screen, users can see the list of added dishes along with price details.
                            - The user here can apply the promo code and other services.
                            - Users can add the order while checking out too."
                                heading_login4="Checkout"
                                discription4="- The user has three options to choose the service (by order, take-away, and dine-in).
                            - The user can see the price details and order or schedule the order as per their needs.
                            - A user can change the location of the delivery point.
                            - When selecting dine-in, the user needs to specify the number of individuals."
                                heading_login5="Payment Method"
                                discription5="- Here the user can check the payment methods.
                            - If the user selects payment by card, they can see the list of added cards there."
                                heading_login6="Order Summary"
                                discription6="The user can see the final order and amount to pay, and by tapping the confirm button, the user can order food."
                                heading_login7="Signup / Login"
                                discription7="- The driver needs to add their bank details, ID proofs and images.
                            - The admin will approve the driver before they log in."
                                heading_login8="Home"
                                discription8="The driver can turn on or off service via the application. The order would only be assigned to the driver who is on the service."
                                heading_login9="Order Request"
                                discription9="- The driver can view the requested order. They need to accept it at a particular time, or the order will get canceled and will get assigned to others.
                            - The driver can only view the order of the customer and restaurant location from where they need to pick up the order."
                                heading_login10="Pickup"
                                discription10="- Once the driver picks up the order, they can see the location of the customer. They can see the map with the location.
                            - The driver can click on the 'navigate' button to open Google Maps with the customer's navigation option."
                                heading_login11="Complete Order"
                                discription11="- Once the driver clicks on the 'complete order,' the order will be completed."
                                heading_login12="Notifications"
                                discription12="- Drivers can view the notifications."
                                heading_login13="Edit Profile"
                                discription13="Here drivers can edit their profile."
                                heading_login14="My Delivery"
                                discription14="Here drivers can see their completed deliveries."
                            />
                        </>
                    )
                })}
                {overview4.map((feature) => {
                    return (
                        <>
                            <Feature {...feature}
                                img_change={projectNowN}
                                classN="justify-content-sm-center"
                                heading_login="My Order Tab"
                                discription="- Check the list of orders
                                - By tapping on the order, the user can check the details regarding the order and cancel it if they want to."
                                heading_login1="Favorite tab"
                                discription1="A list of favorite restaurants is shown here."
                                heading_login2="Folder"
                                discription2="Users can create a folder and save their NFT/ Post to the folder."
                                heading_login3="Account tab"
                                discription3="Users can check their profile at the top right corner."
                                heading_login4="Edit Profile"
                                discription4="A user can edit the profile."
                                heading_login5="My address"
                                discription5="- By tapping on this tab, the user can be redirected to the display in which the consumer can upload multiple addresses to use it anywhere.
                                - There is an option to edit and delete too."
                                heading_login6="Payment Method"
                                discription6="Users can add their debit and credit cards to make payments and see the list of already added cards."
                                heading_login7="Report Issue"
                                discription7="If users can report it by using this button along with the image."
                                heading_login8="Change Language"
                                discription8="Users can change the language of the application."
                                heading_login9="Share App"
                                discription9="Using this, users can share the application."
                                heading_login10="Rate the App"
                                discription10="Using this, a user can rate the application."
                                heading_login11="About Us"
                                discription11="In this section, users can learn about us."
                                heading_login12="Help & FAQ"
                                discription12="In this section, users can check Help & FAQ."
                                heading_login13="Terms & Conditions"
                                discription13="In this section, users can check the terms and conditions of the application."
                                heading_login14="Privacy Policy"
                                discription14="In this section, users can check the privacy policy of the application."
                                heading_login15="Contact Us"
                                discription15="In this section, users can contact us."
                            />
                        </>
                    )
                })}
                {overview5.map((feature) => {
                    return (
                        <>
                            <Feature {...feature}
                                img_change={projectNowN}
                                classN="justify-content-sm-center"
                                heading_login="Logout"
                                discription="Using this, users can log out from their accounts."
                                heading_login1="My Earning"
                                discription1="Drivers can view their earnings - Yearly, Weekly, Monthly, Daily."
                                heading_login2="Change Language"
                                discription2="By using this, drivers can change the language of the application."
                                heading_login3="Bank details"
                                discription3="From here drivers can check and also change their added bank details."
                                heading_login4="Change Password"
                                discription4="From here, drivers can change their account password if they want."
                                heading_login5="Share App"
                                discription5="By using this drivers can share the application."
                                heading_login6="Rate the App"
                                discription6="By using this, drivers can rate the application."
                                heading_login7="About Us"
                                discription7="In this section drivers can check about us."
                                heading_login8="Help & FAQ"
                                discription8="In this section, drivers can check Help & FAQ."
                                heading_login9="Terms & Conditions"
                                discription9="In this section, drivers can check the terms & conditions of the application."
                                heading_login10="Privacy Policy"
                                discription10="In this section, drivers can check the privacy policy of the application."
                                heading_login11="Contact Us"
                                discription11="In this section drivers can contact us."
                                heading_login12="Logout"
                                discription12="Using this, drivers can log out from their accounts."
                                heading_login13="Dashboard"
                                discription13="Super admin can view the total no. of the customer, driver, pending driver, promo code, and cuisine."
                                heading_login14="Sub Admin"
                                discription14="- Super Admin can view all sub-admin lists, edit, active/inactive sub-admin, view sub-admin profiles.
                                - Super Admin can assign access privileges to sub-admin, search, and also delete sub-admin details."
                                heading_login15="Customer"
                                discription15="Super Admin can view all customer lists, edit active/inactive customers, view customer profiles, search customers, export, and also delete customer details."
                            />
                        </>
                    )
                })}


            </Container>
            <Container>
                <SectionTitle>Admin & Panel</SectionTitle>
                {overview3.map((feature, classN) => {
                    return (
                        <>
                            <Feature {...feature}
                                img_change={projectNowN}
                                classN="justify-content-sm-center"
                                heading_login="Driver"
                                discription="Super Admin can view all driver lists, edit active/inactive drivers, view driver profiles, search drivers, and also delete driver details."
                                heading_login1="Pending Driver"
                                discription1="Super Admin can view all lists of the Pending driver in which the admin can approve or decline drivers, edit active/inactive drivers, view driver profiles, search drivers, and also delete driver details."
                                heading_login2="Chat"
                                discription2="Super Admin can view chat history with driver and customer and click on it to see chat details."
                                heading_login3="Restaurant"
                                discription3="The exquisite Admin can view all restaurant lists, add, edit, active/inactive restaurants, view restaurant details, search restaurants, export restaurants, and can view restaurant ratings."
                                heading_login4="Trending Restaurant"
                                discription4="Super Admin can view trending restaurants and set their sequence to show accordingly on the application."
                                heading_login5="Offer Restaurant"
                                discription5="Super Admin can view a list of restaurants in which promo code is active and also set their sequence to show accordingly on the application."
                                heading_login6="Reports"
                                discription6="Admin can export and import the data, sales report, total order report(daily, weekly, monthly)."
                                heading_login7="Login"
                                discription7="The restaurant can log in using the email address and password."
                                heading_login8="Dashboard"
                                discription8="Restaurants can view the total number of notifications(Live orders), menu categories, promo codes, dishes, and restaurant photos, and also total numbers of orders(daily, weekly, monthly) and total sales(daily, weekly, monthly)."
                                heading_login9="Profile"
                                discription9="Restaurants can set their profile, edit their temporary availability status, and set a minimum order amount."
                                heading_login10="Place Order"
                                discription10="For Delivery, restaurants can add customer orders from the panel itself."
                                heading_login11="Menu"
                                discription11="Restaurants can view a list of categories and also add, edit, active/inactive categories, and set their sequence.                                "
                                heading_login12="Dishes"
                                discription12="Restaurants can view a list of dishes and also add, edit, delete, active/inactive dishes and add multiple images for each dish."
                                heading_login13="Promo code"
                                discription13="Super Admin can view all promo code lists, add, edit, active/inactive promo code, view promo code details, search promo code, and delete promo code details."
                                heading_login14="Cuisine"
                                discription14="Super Admin can view all cuisine lists, add, edit, active/inactive cuisine, search cuisine, and delete cuisine details."
                                heading_login15="Notification"
                                discription15="User : List of all sent notifications, send a message to all customers and send an alert with a specific date-time. Driver : List of all sent messages, notifications to the driver chosen, send a notification to all and selected drivers with a specific date-time."
                            />
                        </>
                    )
                })}
                {overview6.map((feature, classN) => {
                    return (
                        <>
                            <Feature {...feature}
                                img_change={projectNowN}
                                classN="div_none_1 justify-content-sm-center"
                                heading_login="Order history"
                                discription="Super Admin can view order history, search order, and view full order details."
                                heading_login1="Pending Orders"
                                discription1="Super Admin can view currently running order, search order, and view full order details."
                                heading_login2="Not Assign Orders"
                                discription2="Super Admin can view a list of orders in which the driver is not assigned and can also assign a driver for those orders."
                                heading_login3="Report Issues"
                                discription3="Super Admin can view the list of reports added by customers from the application and view their report details."
                                heading_login4="Cms pages"
                                discription4="Terms And Conditions : Super admin can change the term content, and this will affect the application for customers and drivers. About us : Super admin can change the about us content concerning the application for customers and drivers. FAQs : Super admin can change FAQ's content, which will affect the application for customers and drivers. Settings : Super Admin can set all necessary details (nearby radios, delivery distance, rate, etc.)"
                                heading_login5="Services"
                                discription5="Restaurants can view lists of services and also add, edit, delete, active/inactive services."
                                heading_login6="Promo code"
                                discription6="Restaurants can view all promo code lists, add, edit, active/inactive promo codes, view promo code details, search promo code, and delete promo code details."
                                heading_login7="Orders"
                                discription7="Restaurants can view all lists of orders according to their order status and also view order details."
                                heading_login8="Vendor Timing"
                                discription8="Restaurants can add two slots in the operating hours."
                                heading_login9="Gallery"
                                discription9="Restaurant can add multiple images for their restaurant which is displayed on application on the restaurant detail page."
                                heading_login10="logout"
                                discription10="Restaurants can log out if and when they want."
                            // heading_login11="Menu"
                            // discription11="Restaurants can view a list of categories and also add, edit, active/inactive categories, and set their sequence.                                "
                            // heading_login12="Dishes"
                            // discription12="Restaurants can view a list of dishes and also add, edit, delete, active/inactive dishes and add multiple images for each dish."
                            // heading_login13="Promo code"
                            // discription13="Super Admin can view all promo code lists, add, edit, active/inactive promo code, view promo code details, search promo code, and delete promo code details."
                            // heading_login14="Cuisine"
                            // discription14="Super Admin can view all cuisine lists, add, edit, active/inactive cuisine, search cuisine, and delete cuisine details."
                            // heading_login15="Notification"
                            // discription15="User : List of all sent notifications, send a message to all customers and send an alert with a specific date-time."
                            />
                        </>
                    )
                })}
            </Container>
            {/* <Container>
                <SectionTitle>Project Approach & Results</SectionTitle>
                {overview1.map(({ headingsection, description2, headingsection3, description3, headingsection2, headingsection1, description1, description, classN }) => (
                    <OurClient
                        classN={classN}
                        heading1={headingsection}
                        description={description}
                        heading2={headingsection1}
                        description2={description1}
                        heading3={headingsection2}
                        description3={description2}
                        heading4={headingsection3}
                        description4={description3}
                        logo1={laptopimg}
                    />
                ))}
            </Container> */}
            <Footer />
        </>
    )
}

export default ProjectOverViewNewNow