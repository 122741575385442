import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

const AddSection = ({ CeoImg, DescriptionCEO, headingCeo, headingCeo1 }) => {
    return (
        <div className="changeing_ceo_div_main">
            <Container>
                <Row>
                    <Col>
                        <center className="d-flex flex-column justify-content-center align-items-center changeing_ceo_div">
                            <img src={CeoImg} alt="" />
                            <p>{DescriptionCEO}</p>
                            <h5>{headingCeo}</h5>
                            <p>{headingCeo1}</p>
                        </center>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default AddSection
