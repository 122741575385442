import React from 'react'
import Nav from '../../../UI/Nav'
import Footer from '../../../UI/Footer'
import { Row, Col } from 'react-bootstrap'
import { OurClient } from '../../Components'

import {
    Banner,
    Container,
    SectionTitle,
    Description,
} from '../../../Style'


import {
    arrowDown,
    OurWorkVector,
    PepperStone,
    PepperStone1,
} from '../../../AllImages'

const ProjectOverViewFour = () => {

    return (

        <>

            <Banner>
                
                <Container>

                    <Nav />
                    
                    <Row className="align-items-center">

                        <Col md={6}>

                            <h1 className="mb-2">Pepperstone Careers</h1>

                            <h1 className="mb-2">Helping find the best talent globally.</h1>

                            <p>Fast-growing Recruitment Technology Company.</p>

                        </Col>

                        <Col className="text-center-md">

                            <OurWorkVector className="img-fluid mt-4 px-md-0 px-2" />

                        </Col>

                    </Row>

                </Container>

            </Banner>

            <div className="center">

                <img src={arrowDown} alt="icon" className="arrow-icon" />

            </div>

            <Container>

                <SectionTitle>Features</SectionTitle>

                {/* <Description className="text-center">Tata Power - Skill Development Institute App Features.</Description> */}

                <OurClient
                    classN="bg-none-style setting_bor setting_bor_1"
                    heading1="The Challenge Summarized"
                    description="For international companies finding a good talent can be challenging. Although there are many recruitment platforms both local and global applicant matching, finding employees with the right skills and corporate values is a serious issue. One of the main reasons is that such platforms have application forms that aren’t in line with companies’ needs, special requirements, and budgets."
                    logo1={PepperStone}
                />

                <OurClient
                    classN="bg-none-style reverse_div setting_bor setting_bor_1"
                    heading1="The Vision"
                    description="Pepperstone is a progressing global tech company specialized in retail FX and CFD trading. The company has offices in 7 countries, and as it was expanding globally, it wanted to communicate its corporate values coherently to applicants worldwide. They decided to create a dynamic and informative website to tell people more about the company and its’ values, promoting current job openings effectively. Additionally, they wanted to have a custom application form with special fields. That’s when they decided to partner with Scopic – a skilled web development partner, who helped Pepperstone in their recruitment efforts."
                    logo1={PepperStone1}
                />

            </Container>

            <Footer />

        </>

    )

}

export default ProjectOverViewFour