import React, { useState } from 'react'
import styled from 'styled-components'
import { Accordion } from 'react-bootstrap'
import { Link, useLocation } from 'react-router-dom'
import {
    logo,
    servicesIconBg,
    Menu,
} from '../AllImages'

const Nav = () => {
    let location = window.location.pathname.split('/')[1]
    let { pathname } = useLocation()
    const [sidebar, setSidebar] = useState(false)

    return (
        <>
            <NavStyled>
                <Link to="/">
                    <img src={logo} alt="logo" />
                </Link>
                <div className="d-flex align-items-center">
                    <Link
                        to="/"
                        className={`link ${pathname === '/' ? 'active' : ''}`}>
                        Home
                    </Link>
                    <div className="position-relative services-space">
                        <Link
                            to="#"
                            className={`link ${location === 'services' ? 'active' : ''}`}>
                            Services
                        </Link>
                        <div className="services-menu">
                            <h1>Our Services</h1>
                            <Link
                                to="/services/software-development"
                                className={`${pathname === '/services/software-development' ? 'active' : ''}`}>
                                Software Development
                            </Link>
                            <Link
                                to="/services/engagement-models"
                                className={`${pathname === '/services/engagement-models' ? 'active' : ''}`}>
                                Engagement Models
                            </Link>
                            <Link
                                to="/services/enterprise-solutions"
                                className={`${pathname === '/services/enterprise-solutions' ? 'active' : ''}`}>
                                Enterprise Solutions
                            </Link>
                            <Link
                                to="/services/emerging-technologies"
                                className={`${pathname === '/services/emerging-technologies' ? 'active' : ''}`}>
                                Emerging Technologies
                            </Link>
                            <Link
                                to="/services/industries"
                                className={`${pathname === '/services/industries' ? 'active' : ''}`}>
                                Industries
                            </Link>
                        </div>
                    </div>
                    <a
                        href="https://blogs.the-webforce.com/"
                        target="_blank"
                        // className={`link ${pathname === '/blogs' || pathname === '/blogs/blog' ? 'active' : ''}`}
                        className="link">
                        Blogs
                    </a>
                    <Link
                        to="/about-us"
                        className={`link ${pathname === '/about-us' ? 'active' : ''}`}>
                        About Us
                    </Link>
                    <Link
                        to="/our-work"
                        className={`link ${pathname === '/our-work' ? 'active' : ''}`}>
                        Our Work
                    </Link>
                    <Link
                        to="/contact-us"
                        className="contact center">
                        Contact Us
                    </Link>
                </div>
                <button
                    className="sidebar-toggle"
                    onClick={() => setSidebar(!sidebar)}>
                    <Menu />
                </button>
            </NavStyled>

            <Sidebar className={sidebar ? 'active' : ''}>
                <div className="head">
                    <img src={logo} alt="logo" />
                    <Menu
                        className="sidebar-toggle"
                        onClick={() => setSidebar(!sidebar)} />
                </div>
                <div className="body">
                    <Link
                        to="/"
                        className={`${pathname === '/' ? 'active' : ''}`}>
                        Home
                    </Link>
                    <Accordion>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Services</Accordion.Header>
                            <Accordion.Body>
                                <Link
                                    to="/services/software-development"
                                    className={`${pathname === '/services/software-development' ? 'active' : ''}`}>
                                    Software Development
                                </Link>
                                <Link
                                    to="/services/engagement-models"
                                    className={`${pathname === '/services/engagement-models' ? 'active' : ''}`}>
                                    Engagement Models
                                </Link>
                                <Link
                                    to="/services/enterprise-solutions"
                                    className={`${pathname === '/services/enterprise-solutions' ? 'active' : ''}`}>
                                    Enterprise Solutions
                                </Link>
                                <Link
                                    to="/services/enterprise-technologies"
                                    className={`${pathname === '/services/enterprise-technologies' ? 'active' : ''}`}>
                                    Emerging Technologies
                                </Link>
                                <Link
                                    to="/services/industries"
                                    className={`${pathname === '/services/industries' ? 'active' : ''}`}>
                                    Industries
                                </Link>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    <a
                        href="https://blogs.the-webforce.com/"
                        target="_blank"
                        // className={`link ${pathname === '/blogs' || pathname === '/blogs/blog' ? 'active' : ''}`}
                        className="link">
                        Blogs
                    </a>
                    <Link
                        to="/about-us"
                        className={`${pathname === '/about-us' ? 'active' : ''}`}>
                        About Us
                    </Link>
                    <Link
                        to="/our-work"
                        className={`${pathname === '/our-work' ? 'active' : ''}`}>
                        Our Work
                    </Link>
                    <div className="center pt-5">
                        <Link
                            to="/contact-us"
                            className="contact">
                            Contact Us
                        </Link>
                    </div>
                </div>
            </Sidebar>
            <BackDrop className={sidebar ? 'active' : ''} />
        </>
    )
}

const NavStyled = styled.nav`
    /* max-width: 1300px; */
    width: 100%;
    height: 75px;
    padding-top: 15px;
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: transparent;
    div.services-space:nth-child(2){
        width: 85px;
        text-align: center;
    }
    a.link:nth-child(3){
        width: 38px;
        text-align: center;
    }
    a.link:nth-child(4),a.link:nth-child(5){
        width: 60px;
        text-align: center;
    }
    a.link {
        text-decoration: none;
        text-align: left;
        font-weight: 400;
        font-size: 14px;
        color: var(--blue);
        margin-right: 30px;
        position: relative;
        &:hover,
        &.active {
            font-weight: bold;
            &:after {
                content: "";
                position: absolute;
                background-color: var(--yellow);
                bottom: -2px;
                left: 0;
                right: 0;
                width: 100%;
                height: 1px;
            }
        }
    }
    a.contact {
        width: 120px;
        height: 35px;
        background-color: var(--blue);
        border-radius: 18px;
        color: white;
        font-size: 14px;
        border: none;
    }
    .services-space {
        padding-bottom: 20px;
        margin-top: 20px;
    }
    .services-space:hover .services-menu,
    .services-menu:hover {
        display: block;
    }
    .services-menu {
        display: none;
        position: absolute;
        top: 35px;
        left: -112px;
        width: 560px;
        height: 274px;
        background-color: #FFFFFF;
        box-shadow: 0px 0px 6px #00000029;
        border-radius: 20px;
        background-image: url(${servicesIconBg});
        background-repeat: no-repeat;
        background-position: right bottom;
        padding: 20px 35px;
        z-index: 500;
        h1 {
            text-transform: capitalize;
            text-align: left;
            font-size: 24px;
            font-family: tungsten;
            line-height: 29px;
            letter-spacing: 0px;
            color: #042154;
            margin-bottom: 20px;
            padding-bottom: 8px;
            border-bottom: 3px solid var(--yellow);
        }
        a {
            display: block;
            width: auto;
            text-align: left;
            font-size: 17px;
            letter-spacing: 0px;
            color: #000D24;
            margin-bottom: 14px;
            text-decoration: none;
            line-height: 17px;
            &:hover {
                font-weight: bold;
            }
            &.active {
                font-weight: bold;
                text-decoration: none;
            }
        }
    }
    .sidebar-toggle {
        display: none;
        border: none;
        background-color: transparent;
        width: 25px;
    }
    @media (max-width:860px) {
        margin-bottom: 55px;
        a.link,
        .contact {
            display: none;
        }
        .sidebar-toggle {
            display: block;
        }
    }
`;

const Sidebar = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 250px;
    height: 100vh;
    overflow-y: auto;
    background-color: #FFFFFF;
    box-shadow: 0px 0px 6px #00000029;
    transition: 0.1s all;
    transform: translateX(-110%);
    z-index: 200;
    &.active {
        transform: translateX(0);
    }
    .head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 18px 20px;
        border-bottom: 1px solid #ececec;
        img {
            width: 95px;
        }
        .sidebar-toggle {
            border: none;
            background-color: transparent;
            width: 20px;
            cursor: pointer;
        }
    }
    .body {
        padding: 20px;
        a {
            display: block;
            width: auto;
            text-align: left;
            font-size: 17px;
            letter-spacing: 0px;
            color: #000D24;
            margin-bottom: 20px;
            text-decoration: none;
            line-height: 17px;
            &:hover {
                font-weight: bold;
            }
            &.active {
                font-weight: bold;
                text-decoration: none;
            }
        }
        a.contact {
            width: 120px;
            height: 35px;
            background-color: var(--blue);
            border-radius: 18px;
            color: white;
            font-size: 14px;
            border: none;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .accordion-item {
            border: 0;
            margin-bottom: 20px;
        }
        .accordion-button {
            padding: 0;
            color: #000D24;
            font-family: 'Roboto';
            box-shadow: none !important;
        }
        .accordion-button:not(.collapsed) {
            background-color: white;
            color: #000D24;
        }
        .accordion-body {
            padding: 20px 0 0 20px;
        }
    }
`;

const BackDrop = styled.div`
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(225, 225, 225, 0.1);
    backdrop-filter: blur(3px);
    z-index: 100;
    &.active {
        display: block;
    }
`

export default Nav