import React from 'react'
import ProjectOverView from '../components/home/our-work/services/ProjectOverView'
import ProjectOverViewNew from '../components/home/our-work/services/ProjectOverViewNew'
import ProjectOverViewNewNow from '../components/home/our-work/services/ProjectOverViewNewNow'
import ProjectOverViewFirst from '../components/home/our-work/services/ProjectOverViewFirst'
import ProjectOverViewFour from '../components/home/our-work/services/ProjectOverViewFour'
import ProjectOverViewFive from '../components/home/our-work/services/ProjectOverViewFive'
import ProjectOverViewSix from '../components/home/our-work/services/ProjectOverViewSix'
// import Test from '../pages/Test'

const Home = React.lazy(() => import('../pages/Home'))
const SoftwareDevelopment = React.lazy(() => import('../pages/services/SoftwareDevelopment'))
const EngagementModels = React.lazy(() => import('../pages/services/EngagementModels'))
const EnterpriseSolutions = React.lazy(() => import('../pages/services/EnterpriseSolutions'))
const EmergingTechnologies = React.lazy(() => import('../pages/services/EmergingTechnologies'))
const Industries = React.lazy(() => import('../pages/services/Industries'))
const OurWork = React.lazy(() => import('../pages/OurWork'))
const AboutUs = React.lazy(() => import('../pages/AboutUs'))
const ContactUs = React.lazy(() => import('../pages/ContactUs'))
const Blogs = React.lazy(() => import('../pages/blogs/Blogs'))
const Blog = React.lazy(() => import('../pages/blogs/Blog'))
const PrivacyPolicy = React.lazy(() => import('../pages/PrivacyPolicy'))
// const notFound = React.lazy(() => import('../views/errorPages/NotFound'))

const routes = [

    { path: '/', exact: true, name: 'Home', component: Home },
    { path: '/services/software-development', exact: true, name: 'Software Development', component: SoftwareDevelopment },
    { path: '/services/engagement-models', exact: true, name: 'Engagement Models', component: EngagementModels },
    { path: '/services/enterprise-solutions', exact: true, name: 'Enterprise Solutions', component: EnterpriseSolutions },
    { path: '/services/emerging-technologies', exact: true, name: 'Enterprise Technologies', component: EmergingTechnologies },
    { path: '/services/industries', exact: true, name: 'Industries', component: Industries },
    { path: '/our-work', exact: true, name: 'Our Work', component: OurWork },
    { path: '/about-us', exact: true, name: 'About Us', component: AboutUs },
    { path: '/project-overview', exact: true, name: 'Project Overview', component: ProjectOverView },
    { path: '/project-overview-new', exact: true, name: 'Project Overview New', component: ProjectOverViewNew },
    { path: '/project-overview-first', exact: true, name: 'Project Overview First', component: ProjectOverViewFirst },
    { path: '/project-overview-four', exact: true, name: 'Project Overview Four', component: ProjectOverViewFour },
    { path: '/project-overview-five', exact: true, name: 'Project Overview Five', component: ProjectOverViewFive },
    { path: '/project-overview-six', exact: true, name: 'Project Overview Six', component: ProjectOverViewSix },
    { path: '/project-overview-new-now', exact: true, name: 'Project Overview New Now', component: ProjectOverViewNewNow },
    // { path: '/Test', exact: true, name: 'Test', component: Test },
    { path: '/contact-us', exact: true, name: 'Contact Us', component: ContactUs },
    { path: '/blogs', exact: true, name: 'Blogs', component: Blogs },
    { path: '/blogs/blog', exact: true, name: 'Blog', component: Blog },
    { path: '/privacy-policy', exact: true, name: 'Privacy Policy', component: PrivacyPolicy },

]

export default routes