import styled from "styled-components"
import { Link } from "react-router-dom"
import { Row, Col } from 'react-bootstrap'
import {
    Container,
    LearnMore,
} from './Style'
import { logo } from "./AllImages"

const UnderLineCard = ({
    Icon,
    title,
    description,
}) => {
    return (
        <UnderLineCardStyled>
            <Icon />
            <h1 className="blue">{title}</h1>
            <p>{description}</p>
        </UnderLineCardStyled>
    )
}

const UnderLineCardStyled = styled.div`
    min-height: 320px;
    background-color: #FFFFFF;
    box-shadow: 0px 0px 6px #00000029;
    border-radius: 10px;
    margin-bottom: 25px;
    padding: 38px 30px 30px 30px;
    position: relative;
    &::after {
        content:"";
        position: absolute;
        background-color: var(--yellow);
        border-radius: 4px;
        width: 130px;
        height: 6px;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
    }
    svg {
        width: 51px;
        height: 51px;
        margin-bottom: 16px;
    }
    h1 {
        text-align: left;
        font-size: 18px;
        letter-spacing: 0px;
        margin-bottom: 16px;
        font-weight: bold;
    }
    p {
        text-align: left;
        font-size: 14px;
        letter-spacing: 0px;
        color: #000D24;
    }
    @media (max-width:576px) {
        min-height: 350px;
        padding: 30px 20px 30px 20px;
    }
`;

const SimpleCard = ({
    Icon,
    title,
    descp,
    classN,
}) => {
    return (
        <SimpleCardStyled className={classN}>
            {Icon && <Icon />}
            <h1>{title}</h1>
            <p>{descp}</p>
        </SimpleCardStyled>
    )
}

const SimpleCardStyled = styled.div`
    min-height: 280px;
    background-color: #FFFFFF;
    border: 1px solid #F1F1F1;
    border-radius: 15px;
    transition: 0.2s all;
    padding: 34px 19px;
    margin-bottom: 25px;
    cursor: pointer;
    &.height-245 {
        min-height: 245px;
    }
    &.height-200 {
        min-height: 200px;
    }
    &.hover-yellow {
        &:hover {
            box-shadow: none;
            background-color: var(--yellow);
            h1, p {
                color: white;
            }
            svg {
                fill: white;
                path {
                    fill: white;
                }
            }
        }
    }
    &.hover-blue {
        path {
            fill: var(--blue);
        }
        h1 {
            color: var(--blue);
        }
        &:hover {
            box-shadow: none;
            background-color: var(--blue);
            h1, p {
                color: white;
            }
            svg {
                fill: white;
                path {
                    fill: white;
                }
            }
        }
    }
    &.hover-blue-top-line {
        path {
            fill: var(--blue);
        }
        h1 {
            color: var(--blue);
        }
        &:hover {
            box-shadow: none;
            background-color: var(--blue);
            h1, p {
                color: white;
            }
            svg {
                fill: white;
                path {
                    fill: white;
                }
            }
            &::before {
                content: "";
                position: absolute;
                background-color: white;
                border-radius: 4px;
                width: 130px;
                height: 6px;
                top: 4px;
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }
    &.hover-underline {
        position: relative;
        svg {

        }
        &:hover {
            &:after {
                content: "";
                position: absolute;
                background-color: var(--yellow);
                border-radius: 4px;
                width: 130px;
                height: 6px;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }
    &.hover-underline-blue {
        position: relative;
        h1, p {
            color: var(--blue);
        }
        svg, path {
            fill: var(--blue);
        }
        &:hover {
            &:after {
                content: "";
                position: absolute;
                background-color: var(--blue);
                border-radius: 4px;
                width: 130px;
                height: 6px;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }
    &:hover {
        box-shadow: 0px 0px 6px #00000029;
    }
    svg {
        max-width: 55px;
        max-height: 41px;
        margin-bottom: 15px;
        fill: var(--yellow);
        path {
            fill: var(--yellow);
        }
    }
    h1 {
        text-align: left;
        font-size: 18px;
        font-weight: bold;
        line-height: 22px;
        letter-spacing: 0px;
        color: var(--yellow);
        margin-bottom: 10px;
    }
    p {
        text-align: left;
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0px;
        color: #000D24;
        margin: 0;
    }
    &.stroke-yellow {
        path {
            stroke: var(--yellow);
        }
    }
    &.translate-minus-r {
        transform: translateX(40px);
    }
    &.translate-minus-l {
        transform: translateX(-40px);
    }
    .h1-h-44 {
        h1 {
            height: 44px;
        }
    }
    @media (max-width:1200px) {
        &.translate-minus-r,
        &.translate-minus-l {
            transform: translateX(0);
        }
    }
`;

const OurWorkComponent = ({
    classN,
    logo,
    projectName,
    projectName1,
    description,
    bgLayer,
    img,
    link,
    // match ={`${useRouteMatch()}`}
}) => {
    return (
        <OurWorkStyled className={classN}>
            <Container>
                <Row>
                    <Col lg={6} className="d-flex flex-column justify-content-center logo_background_change">
                        <img src={logo} alt="project logo" className="logo" />
                        <h1 className="blue">{projectName}</h1>
                        <h2 className="blue">{projectName1}</h2>
                        <p>{description}</p>
                        <Link to={link}>
                            <LearnMore className="hover">Learn More</LearnMore>
                        </Link>
                    </Col>
                    <Col lg={6}>
                        <div className="img-div">
                            <img src={bgLayer} alt="layer" className="bg-layer" />
                            <img src={bgLayer} alt="layer" className="bg-layer2" />
                            <img src={img} alt="project demo" className="img-fluid" />
                        </div>
                    </Col>
                </Row>
            </Container>
        </OurWorkStyled>
    )
}

const OurWorkStyled = styled.div`
    overflow-x: hidden;
    margin-top: 140px;
    margin-bottom: 95px;
    &.reverse {
        .row {
            flex-direction: row-reverse;
        }
        .bg-layer {
            left: -5%;
        }
        .bg-layer2 {
            left: -50%;
        }
    }
    .logo {
        max-width: 150px;
        margin-bottom: 16px;
    }
    h1 {
        text-align: left;
        font-size: 50px;
        letter-spacing: 0px;
        margin-bottom: 16px;
        font-weight: 900;
    }
    p {
        text-align: left;
        font-size: 14px;
        letter-spacing: 0px;
        color: #000D24;
        margin-bottom: 30px;
    }
    button {
        margin-bottom: 50px;
    }
    .img-div {
        min-height: 510px;
        display: flex;
        align-items: center;
        position: relative;
        .bg-layer {
            position: absolute;
            right: -5%;
            top: 0;
            bottom: 0;
        }
        .bg-layer2 {
            position: absolute;
            right: -50%;
            top: 0;
            bottom: 0;
        }
        .img-fluid {
            z-index: 100;
        }
    }
    @media (max-width:1110px) {
        &.reverse {
            .bg-layer {
                left: -20%;
            }
            .bg-layer2 {
                left: -50%;
            }
        }
        .img-div {
            .bg-layer {
                right: -20%;
            }
            .bg-layer2 {
                right: -50%;
            }
        }
    }
    @media (max-width:992px) {
        margin-top: 80px;
        .img-div {
            justify-content: end;
        }
        &.reverse {
            .img-div {
                justify-content: flex-start;
            }
        }
    }
    @media (max-width:576px) {
        .img-div {
            justify-content: center;
        }
        &.reverse {
            .img-div {
                justify-content: center;
            }
        }
    }
`;

const NumberCard = ({
    number,
    title,
    description,
    classN
}) => {
    return (
        <NumberCardStyled className={classN}>
            <h1>{title}</h1>
            <p>{description}</p>
            <h5>{number}</h5>
        </NumberCardStyled>
    )
}

const NumberCardStyled = styled.div`
    width: 100%;
    min-height: 250px;
    background-color: #FEFEFE;
    border: 1px solid #F1F1F1;
    border-radius: 15px;
    margin-bottom: 30px;
    padding: 55px 20px 30px 20px;
    position: relative;
    &.height-166 {
        min-height: 166px;
    }
    h1 {
        text-align: left;
        font-size: 16px;
        font-weight: 600;
        line-height: 19px;
        margin-bottom: 10px;
        letter-spacing: 0px;
        color: var(--blue);
    }
    p {
        text-align: left;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0px;
        color: var(--blue);
    }
    h5 {
        position: absolute;
        bottom: -6px;
        right: 0;
        line-height: 0.7;
        font-size: 196px;
        letter-spacing: 0px;
        color: var(--blue);
        opacity: 0.1;
    }
`;

const LearnMoreCard = ({
    Icon,
    title,
    description,
    link,
}) => {
    return (
        <LearnMoreCardStyled>
            <div>
                <Icon />
                <h1>{title}</h1>
                <p>{description}</p>
            </div>
            <Link to={link}>Learn More</Link>
        </LearnMoreCardStyled>
    )
}

const LearnMoreCardStyled = styled.div`
    min-height: 332px;
    background-color: #FFFFFF;
    border: 1px solid #F1F1F1;
    border-radius: 15px;
    padding: 35px 30px;
    margin-bottom: 35px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    svg {
        max-width: 55px;
        max-height: 41px;
        margin-bottom: 15px;
        fill: var(--blue);
        path {
            fill: var(--blue);
        }
    }
    h1 {
        text-align: left;
        font-size: 18px;
        font-weight: bold;
        line-height: 22px;
        letter-spacing: 0px;
        color: var(--blue);
        margin-bottom: 10px;
    }
    p {
        text-align: left;
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0px;
        color: #000D24;
        margin: 0 0 8px 0;
    }
    a {
        visibility: hidden;
        text-decoration: none;
        text-align: left;
        font-optical-sizing: 17px;
        letter-spacing: 0px;
        color: var(--yellow);
    }
    &:hover {
        background-color: var(--blue);
        a {
            visibility: visible;
        }
        path {
            fill: var(--blue);
        }
        h1, p {
            color: white;
        }
        svg {
            fill: white;
            path {
                fill: white;
            }
        }
        &::before {
            content: "";
            position: absolute;
            background-color: var(--yellow);
            border-radius: 4px;
            width: 130px;
            height: 6px;
            bottom: 4px;
            left: 50%;
            transform: translateX(-50%);
        }
    }
`;

const Loading = () => {
    return (
        <LoadingStyled>
            <img src={logo} alt="The Web Force" />
        </LoadingStyled>
    )
}

const LoadingStyled = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 500;
    img {
        animation-name: opacity;
        animation-duration: 1s;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out;
    }
    @keyframes opacity {
        0% {
            opacity: 1;
        }
        25% {
            opacity: 0.7;
        }
        50% {
            opacity: 0.4;
        }
        75% {
            opacity: 0.6;
        }
        80% {
            opacity: 0.8;
        }
        100% {
            opacity: 1;
        }
    }
`;

export {
    UnderLineCard,
    SimpleCard,
    OurWorkComponent,
    NumberCard,
    LearnMoreCard,
    Loading,
}