import React from 'react'
import Nav from '../../../UI/Nav'
import Footer from '../../../UI/Footer'
import { Row, Col } from 'react-bootstrap'
import { OurClient } from '../../Components'

import {
    Banner,
    Container,
    SectionTitle,
    Description,
} from '../../../Style'


import {
    arrowDown,
    OurWorkVector,
    PepperStone,
    PepperStone1,
    ChalImg,
    ChalImg1,
    ChalImg2,
    COOCeo,
    COOCeo1,
} from '../../../AllImages'
import AddSection from './AddSection'

const ProjectOverViewFive = () => {

    return (

        <>

            <Banner>

                <Container>

                    <Nav />

                    <Row className="align-items-center">

                        <Col md={6}>

                            <h1 className="mb-2">Realmfive – IoT Agritech Case Study.</h1>

                            <h1 className="mb-2">Get the best wireless connectivity built for agriculture.</h1>

                            <p>RealmFive</p>

                        </Col>

                        <Col className="text-center-md">

                            <OurWorkVector className="img-fluid mt-4 px-md-0 px-2" />

                        </Col>

                    </Row>

                </Container>

            </Banner>

            <div className="center">

                <img src={arrowDown} alt="icon" className="arrow-icon" />

            </div>

            <Container>

                <SectionTitle>Features</SectionTitle>

                {/* <Description className="text-center">Tata Power - Skill Development Institute App Features.</Description> */}

                <OurClient
                    classN="bg-none-style setting_bor setting_bor_1"
                    heading1="The Challenge"
                    description="Since the RealmFive group contains a gigantic encounter in AgTech, we knew they required a smart arrangement to the issue they were attempting to illuminate. Subsequently, Geniusee group has participated with the RealmFive group to pick up commerce esteem rapidly with making quality code. Amid our to begin with collaboration, RealmFive was trying to find Tractors Following ReactNative App. After weighing all the aces and cons, our groups came to the conclusion that a Local Application composed for IOS and Android independently will be more viable. To begin with, information collecting and handling will gotten to be speedier and more accurate that would make it less demanding to survey the viability of the complete application. Besides, the local application altogether progresses the client encounter, making the application as comfortable as conceivable to utilise. Too, the local app turned out to be an incredible arrangement since of the adjust between execution and cost. Key challenges in RealmFive project included"
                    logo1={ChalImg}
                />

                <AddSection
                    CeoImg={COOCeo}
                    DescriptionCEO="Our cooperation with RealmFive has become an excellent experience in working with the development of IoT applications. We believe that the company will continue to grow, expand its market and achieve new successes in the field of AgTech."
                    headingCeo="Pavlo Kytsmey"
                    headingCeo1="COO Geniusee"
                />

                <OurClient
                    classN="bg-none-style reverse_div setting_bor setting_bor_1"
                    heading1="The Process"
                    description="Realm Five develops devices that collect various data, such as soil moisture, rainfall, amount of water in tanks, condition of tractors and their location, etc. from different parts of agriculture. Therefore, we decided to use Swift and Kotlin to write an IoT application. The code written in Swift looks very simple, the language is intuitive to every programmer. Kotlin is very similar to Swift in its laconicism. Also, these languages allowed us to bring to life an excellent and convenient UX/UI design developed by our team. In cooperation with RealmFive, we have chosen Scrum. This methodology helped us to provide the client with features on demand and quickly adapt to new requirements. We use Slack, Jira, have everyday meetings and work in weekly sprints. For a deeper understanding of the business idea, the necessary features and technologies for our client and simply hang out, we organize physical meetings in the USA about once every six months Core activities at this stage include: Native IOS/Android App Development to collect data from IoT devices UX/UI Design for easier user interaction with the app Increasing stability and maintainability by generating types based on our GraphQL"
                    logo1={ChalImg1}
                />

                <AddSection
                    CeoImg={COOCeo1}
                    DescriptionCEO="“The team has exceeded the company’s expectations with their ability to deliver fully-functioning, robust products. They were able to establish a transparent, flexible relationship with the internal staff by designating a project manager for the account. Their open communication skills added value.”"
                    headingCeo="Jesse Vadnais"
                    headingCeo1="Marketing and Customer Experience Manager at RealmFive"
                />

                <OurClient
                    classN="bg-none-style setting_bor setting_bor_1"
                    heading1="The Outcome"
                    description="A well-chosen stack of used technologies has improved the productivity and efficiency of data collection and processing. To the existing funding of $ 7M, RealmFive raised $ 1M +, which helps to continue developing innovative technologies in the field of AgTech. The American Society of Agricultural and Biological Engineers (ASABE) has awarded RealmFive four of its AE50 Awards for ingenious new designs in the agriculture industry. More than 50k users have already downloaded the application. The average rating in Google Play and App Store is 4.5. The success of this project hinged on the following factors: Choosing the right technology stack for the IoT App Regular daily meetings, transparency in communication and implementing best practices of Scrum development Open feedback loop between Geniusee and RealmFive teams"
                    logo1={ChalImg2}
                />

            </Container>

            <Footer />

        </>

    )

}

export default ProjectOverViewFive