import React from 'react'
import Nav from '../../../UI/Nav'
import Footer from '../../../UI/Footer'
import { Row, Col, NavItem } from 'react-bootstrap'
import { OurClient } from '../../Components'
import { OurWorkComponent } from '../../../Components'
import { CenterImage, Feature } from './Components'
import overview3 from "./Item"
// import all from "../../../AllImages"

import {
    Banner,
    Container,
    SectionTitle,
    // SectionDesc,
    // Heading,
    // heading1,
    Description,
    // LearnMore,
    // BgBlue,
} from '../../../Style'


import {
    arrowDown,
    // BlueDots,
    OurWorkVector,
    // heartbeat,
    // carribean,
    // ourFamilyDollar,
    // ourGlobal,
    // ourLsu,
    // astrazeneca,
    // eVisicLogo,
    // blueBgBox,
    // eVisicThumbnail,
    // eVisicThumbnail1,
    // eVisicThumbnail2,
    // pinkBgBox,
    // purpleBgBox,
    // mblProjectDemo,
    // mblProjectDemo1,
    mobile_multi,
    laptopimg,
    projectNew,
    projectNew1,
    projectNew2,
    projectNew4,
    // logoPlaceholder1,
    // logoPlaceholder2,
    // logoPlaceholder3,
} from '../../../AllImages'

const ProjectOverViewNew = ({
    heading_login,
    discription,
    heading_login1,
    discription1,
    heading_login2,
    discription2,
    heading_login3,
    discription3,
    heading_login4,
    discription4,
    heading_login5,
    discription5,
    heading_login6,
    discription6,
    heading_login7,
    discription7,
    heading_login8,
    discription8,
    heading_login9,
    discription9,
    heading_login10,
    discription10,
    heading_login11,
    discription11,
    heading_login12,
    discription12,
    heading_login13,
    discription13,
    heading_login14,
    discription14,
}) => {

    const overview = [
        {
            "classN": "bg-none-style setting_bor setting_bor_1",
            "headingsection": "The Challenge Summarized",
            "description": "Translating your restorative pictures by yourself can be extreme, particularly in the event that you’re not a restorative proficient. So, on the off chance that you need to urge a moment's supposition on your radiology pictures – you have got to go to a medical centre. Unfortunately, getting personalised radiology reports from a clinic isn't a simple solution either. Reaching to the radiology centre can be quite costly in terms of cash and time: you may need to commute, hold up within the line uncertainty, and pay hundreds of dollars to induce a proficient to require a see your restorative imaging. All these variables can make individuals watchful of their clinic visits. Wouldn’t it be awesome to have a one-stop shop for all your therapeutic imaging needs from the consolation of your domesticity?",
        },
        {
            "classN": "bg-none-style reverse_div setting_bor setting_bor_1",
            "headingsection": "The Vision",
            "description": "Mediphany founders recognized these inefficiencies, and decided to create an all-in-one radiology imaging analysis platform. They were looking for experienced HIPAA-compliant medical developers, and that’s how they found Scopic. Mediphany (Medical+Epiphany) is a radiology imaging software, that helps patients read their MRI and CTI scans with a help of high-quality 3D models and scans for comparison. With Mediphany, anyone can upload medical images safely, and get personal guidance from leading radiology industry professionals at a fraction of the time that it would normally take them. Getting the guidance of qualified radiology professionals remotely has never been easier!"
        },
    ]

    const overview3 = [
        {
            headingsection: "Dashboard:",
            description: "• Admin has a dashboard that contains different chart wise data, so it is easy to get data analysis. You can find Users, Ratio, Sale, Active users with months, Registration of users, KYC status, and redemption data in a chart manner.",
            headingsection1: "User:",
            description1: "• Admin can have all user's details with filtration options. Admin can export all user data with its filter. Admin can add, edit, and view user details. There is also a search feature to help in user searching.",
        },
    ]
    const overview1 = [
        {
            classN: "bg-none-style reverse_div setting_bor setting_bor_1",
            headingsection: "Project Approach & Results",
            description: "Looking at the client's expectations, our Hyperlink team designed the app as per their requirements. We designed a thorough document list that included all the points and scenarios, created flowcharts and SRS. After completing the documentation and diagram process, we began working on a design phase. We assigned a designer and one senior UI/UX designer to work on this. Once designs got approved by the client, we began working on the development phase. Just replace this with the one I send. Before starting the development, we chose the tech stack for building the app and admin panel. We chose native technology swift for iOS development & Kotlin for Android and used JSON-based rest API development with PHP & Laravel Framework. Our skilled developers also created one admin panel to manage users, webinars, courses, etc., using Angular 9. After the development phase, we began the testing phase and deployment, where we tested all the app modules and began processing the deployment process.",
        },
        {
            classN: "bg-none-style setting_bor setting_bor_1",
            headingsection: "Challenges",
            description: "1. Data Info, Images, & Video Management The app's primary feature is to provide all types of information, such as details about the TPSDI profile, facility details, training center details, etc. The app also gives all the pictures with the video associated with each detail to understand easily. The main challenge was to manage all the information and display a vast number of images that users could easily find and smoothly use the app. Our expert developers handled such massive data by implementing some compressing and custom algorithms.",
            description1: "2. Multiple Oauth Implementation Oauth is implemented for the app to authenticate the API request. We needed multiple Oauth implementations, one for APP, and the rest for admin. It was a challenging task to validate and destroy the token separately. However, we implemented one algorithm to resolve this issue and thus managed authentication.",
        },
    ]



    return (
        <>
            <Banner>
                <Container>
                    <Nav />
                    <Row className="align-items-center">
                        <Col md={6}>
                            <h1 className="mb-2">Mediphany</h1>
                            <h1 className="mb-2">Cross-Platform Radiology Software</h1>
                            <p>Upload your images and get personalized MRI or CT video reports</p>
                        </Col>
                        <Col className="text-center-md">
                            {/* <img src={OurWorkVector} alt="vector" className="img-fluid mt-4" /> */}
                            <OurWorkVector className="img-fluid mt-4 px-md-0 px-2" />
                        </Col>
                    </Row>
                </Container>
            </Banner>
            <div className="center">
                <img src={arrowDown} alt="icon" className="arrow-icon" />
            </div>
            {/* <div className="position-relative overflow-x-hidden">
                <BlueDots className="blue-dots right" />
            </div> */}
            <Container>
                <SectionTitle>Features</SectionTitle>
                {/* <Description className="text-center">Tata Power - Skill Development Institute App Features.</Description> */}
                {overview.map(({ headingsection, description, classN }) => (
                    <>
                        <OurClient
                            classN={classN}
                            heading1={headingsection}
                            description={description}
                            logo1={projectNew1}
                        />
                    </>
                ))}
            </Container>
            {/* <Container>
                <SectionTitle>Features & (Customers App)</SectionTitle>


                {overview3.map((feature) => {
                    // console.log(feature, index)
                    return (
                        <>
                            <Feature {...feature}
                                img_change={projectNew2}
                                classN="justify-content-sm-center"
                                heading_login="Signup"
                                discription="• Register using essential details like the first name, last name, email, mobile number, password, etc. • You can also signup using Apple, Google, or Facebook."
                                heading_login1="Login"
                                discription1="• Log in with email and password.•  You can also log in with Apple, Google, or Facebook.•  Users can enter the OTP from their emails for email verification."
                                heading_login2="Leadership Team"
                                discription2="• Users can view all the leadership team members."
                                heading_login3="Training Hubs"
                                discription3="• Users can find all the training hub details."
                                heading_login4="Facilities @TPSDI"
                                discription4="• Users can find all the facilities list with images provided by TPSDI."
                                heading_login5="Webinar"
                                discription5="• Users can view all the upcoming and past webinar list with webinar details, registration link. Moreover, users can also watch past webinar video."
                                heading_login6="Online Courses"
                                discription6="• In this section, users can view all the available online course list with course details."
                                heading_login7="Forgot Password"
                                discription7="• OTP will be sent to the user's registered email address to verify their account and give a password reset option."
                                heading_login8="About Us"
                                discription8="• On Users can view all the information about TPSDI like profile, training approach, customer speaks, awards & recognition, etc."
                                heading_login9="Gift Store"
                                discription9="• Users can redeem their points from the gift store. The gift will be delivered to the address or can pick up from nearby gift centers by the user's choice."
                                heading_login10="TPSDI Offerings"
                                discription10="• Users can view all the TPSDI training offer details for corporate, engineering students & skill development programs with images."
                                heading_login11="Contact Us"
                                discription11="• Users can find all the contact information for all training hubs."
                                heading_login12="Life @TPSDI"
                                discription12="• Users can watch all the videos of TPSDI life."
                                heading_login13="Account"
                                discription13="• In this section, users can manage their account like change password, edit profile, red disclaimer & privacy policy, and share the application with friends."
                                heading_login14="Notification"
                                discription14="• Users can get all the notifications for upcoming events, webinars, and courses."
                            />
                        </>
                    )
                })}


            </Container> */}
            {/* <Container>
                <SectionTitle>Admin & Panel</SectionTitle>
                <Description className="text-center">Over 80+ organizations have trusted us to work with them.</Description>
                {overview1.map(({ headingsection, description2, headingsection3, description3, headingsection2, headingsection1, description1, description, classN }) => (
                    <OurClient
                        classN={classN}
                        heading1={headingsection}
                        description={description}
                        heading2={headingsection1}
                        description2={description1}
                        heading3={headingsection2}
                        description3={description2}
                        heading4={headingsection3}
                        description4={description3}
                        logo1={laptopimg}
                    />
                ))}
                {overview3.map((feature, classN) => {
                    // console.log(feature, index)
                    return (
                        <>
                            <Feature {...feature}
                                img_change={projectNew2}
                                classN="div_none justify-content-sm-center"
                                heading_login="Login"
                                discription="• Admin can login with their Email and Password."
                                heading_login1="Dashboard"
                                discription1="• Admin can see live number counts of users, number of webinars, and number of courses. Admin can manage users, webinars, courses, training metrics, and notifications from the dashboard. • Admin can also change their password after login."
                                heading_login2="Users"
                                discription2="• Admin can navigate the users from the navigation panel, and the user list will be displayed. Admin can view specific user details by clicking the eye icon in the list. • Admin can delete, activate/deactivate from the list."
                                heading_login3="Webinars"
                                discription3="• Admin can view a webinar list and specific webinar details by clicking the eye icon in the list and delete a particular webinar by clicking the trash icon in the list. • Admin can add new webinars, edit webinars, and complete specific webinars."
                                heading_login4="Courses"
                                discription4="• Admin can view the course list and specific course details by clicking the eye icon in the list and delete particular courses by clicking the trash icon in the list. • Admin can add new courses and edit courses."
                                heading_login5="Training Metrics"
                                discription5="• Admin can view the training metric list and specific training metric details by clicking the eye icon in the list and deleting a particular training metric by clicking the trash icon. • Admin can add new training metrics, edit webinars, and delete particular training metrics."
                                heading_login6="Notification"
                                discription6="• Admin can send notifications from this page."
                            />
                        </>
                    )
                })}
            </Container>
            <Container>
                <SectionTitle>Project Approach & Results</SectionTitle>
                {overview1.map(({ headingsection, description2, headingsection3, description3, headingsection2, headingsection1, description1, description, classN }) => (
                    <OurClient
                        classN={classN}
                        heading1={headingsection}
                        description={description}
                        heading2={headingsection1}
                        description2={description1}
                        heading3={headingsection2}
                        description3={description2}
                        heading4={headingsection3}
                        description4={description3}
                        logo1={projectNew4}
                    />
                ))}
                {overview3.map((feature, classN) => {
                    // console.log(feature, index)
                    return (
                        <>
                            <Feature {...feature}
                                classN="div_none justify-content-sm-center"
                                heading_login="Login"
                                discription="• Admin can login with their Email and Password."
                                heading_login1="Dashboard"
                                discription1="• Admin can see live number counts of users, number of webinars, and number of courses. Admin can manage users, webinars, courses, training metrics, and notifications from the dashboard. • Admin can also change their password after login."
                                heading_login2="Users"
                                discription2="• Admin can navigate the users from the navigation panel, and the user list will be displayed. Admin can view specific user details by clicking the eye icon in the list. • Admin can delete, activate/deactivate from the list."
                                heading_login3="Webinars"
                                discription3="• Admin can view a webinar list and specific webinar details by clicking the eye icon in the list and delete a particular webinar by clicking the trash icon in the list. • Admin can add new webinars, edit webinars, and complete specific webinars."
                                heading_login4="Courses"
                                discription4="• Admin can view the course list and specific course details by clicking the eye icon in the list and delete particular courses by clicking the trash icon in the list. • Admin can add new courses and edit courses."
                                heading_login5="Training Metrics"
                                discription5="• Admin can view the training metric list and specific training metric details by clicking the eye icon in the list and deleting a particular training metric by clicking the trash icon. • Admin can add new training metrics, edit webinars, and delete particular training metrics."
                                heading_login6="Notification"
                                discription6="• Admin can send notifications from this page."
                            />
                        </>
                    )
                })}
            </Container> */}
            <Footer />
        </>
    )
}

export default ProjectOverViewNew