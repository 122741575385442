import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Row, Col } from 'react-bootstrap'
import { Container } from '../Style'
import {
    FooterLogo,
    Facebook,
    Instagram,
    Twitter,
    Linkedin,
    email,
    phone,
    location
} from '../AllImages'

const Footer = () => {
    return (
        <FooterStyled>
            <Container>
                <Row>
                    <Col lg={4} md={6}>
                        <FooterLogo className="logo" />
                        <p className="text pe-lg-5">The Web Force has transformed the business with relevant needed Digital Solutions over the period to help these Industries & Businesses to maintain their quality and standards within the largest market without hampering their success. Our tailored engineers focus on every aspect of the clients requirements to tackle their specified challenges.</p>
                    </Col>
                    <Col lg={2} xs={6}>
                        <h1>Services</h1>
                        <Link to="/services/software-development" className="text">Software Development</Link>
                        <Link to="/services/engagement-models" className="text">Engagement Models</Link>
                        <Link to="/services/enterprise-solutions" className="text">Enterprise Solutions</Link>
                        <Link to="/services/enterprise-technologies" className="text">Emerging Technologies</Link>
                        <Link to="/services/industries" className="text">Industries</Link>
                    </Col>
                    <Col lg={2} xs={6}>
                        <h1>Home</h1>
                        <Link to="/blogs" className="text">Blog</Link>
                        <Link to="/about-us" className="text">About Us</Link>
                        <Link to="/our-work" className="text">Our Work</Link>
                        <Link to="/contact-us" className="text">Contact Us</Link>
                        <Link to="/privacy-policy" className="text">Privacy Policy</Link>
                    </Col>
                    <Col lg={3} md={6}>
                        <h1>Contact Us</h1>
                        <div className="info">
                            <div>
                                <a href='mailto:hello@the-webforce.com' target="_blank" className='pb-2'>
                                    <img src={email} alt="emial" width="14px" className="mr-2" />
                                    hello@the-webforce.com
                                </a>
                                <a href='tel:+1 (470) 219-4660' className='pb-2'>
                                    <img src={phone} alt="emial" width="14px" className="mr-2" />
                                    +1 (470) 219-4660
                                </a>
                                <span className='pb-2'>
                                    <img src={location} alt="emial" width="14px" className="mr-2" />
                                    30 N Gould St STE 7001 Sheridan, WY 82801, US
                                </span>
                                <span className='pb-2'>
                                    <img src={location} alt="emial" width="14px" className="mr-2" />
                                    M-72 Zanaib Tower Model town link road Lahore Pakistan
                                </span>
                            </div>
                        </div>
                        <Link to="/contact-us">
                            <button>Get In Touch</button>
                        </Link>
                        <div className="center">
                            <Link to="https://www.facebook.com/thewebforce1/" target="_blank" className="social">
                                <Facebook />
                            </Link>
                            <Link to="ttps://www.instagram.com/theweb.force/" target="_blank" className="social">
                                <Instagram />
                            </Link>
                            <Link to="https://twitter.com/TheWebForce2" target="_blank" className="social">
                                <Twitter />
                            </Link>
                            <Link to="https://www.linkedin.com/company/the-webforce/mycompany/" target="_blank" className="social">
                                <Linkedin />
                            </Link>
                        </div>
                    </Col>
                </Row>
            </Container>
            <div className="copy-right center">
                @2022 All Rights Reserved. The Web Force.
            </div>
        </FooterStyled>
    )
}

const FooterStyled = styled.footer`
    background-color: #000816;
    padding: 50px 0 0 0;
    .logo {
        margin-bottom: 20px;
    }
    .text {
        text-align: left;
        font-size: 14px;
        font-weight: 300;
        letter-spacing: 0.21px;
        color: #FFFFFF;
        margin-bottom: 15px;
        text-decoration: none;
        display: block;
    }
    a.text {
        &:hover {
            color: var(--yellow);
        }
    }
    h1 {
        margin-top: 78px;
        margin-bottom: 10px;
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0px;
        color: #FCFCFC;
    }
    button {
        width: 100%;
        height: 35px;
        background-color: #FCFCFC;
        border-radius: 23px;
        border: none;
        text-align: center;
        font-size: 15px;
        letter-spacing: 0px;
        color: var(--blue);
        margin-bottom: 20px;
        &:hover {
            background-color: var(--blue);
            color: white;
        }
    }
    .social {
        margin: 0 7px;
        svg {
            width: 20px;
            height: 20px;
            &:hover {
                fill: var(--yellow);
                path {
                    fill: var(--yellow);
                }
            }
        }
    }
    .copy-right {
        margin-top: 60px;
        width: 100%;
        min-height: 50px;
        background-color: #090909;
        text-align: center;
        font-size: 14px;
        letter-spacing: 0.21px;
        color: #A5A5A5;
    }
    .info {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        img {
            margin-right: 10px;
        }
        span, a {
            text-align: left;
            font-size: 14px;
            letter-spacing: 0.21px;
            color: #FFFFFF;
            display: block;
        }
    }
`;

export default Footer