import styled from "styled-components"
import {
    bannerLayer
} from './AllImages'

const Container = styled.div`
    max-width: 1400px;
    width: 100%;
    margin: auto;
    padding: 0 60px;
    @media (max-width:992px) {
        padding: 0 45px;
    }
    @media (max-width:768px) {
        padding: 0 20px;
    }
`;

const Banner = styled.div`
    background-image: url(${bannerLayer});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right bottom;
    min-height: 571px;
    width: 100%;
    h1 {
        text-align: left;
        font-size: 60px;
        font-family: tungsten;
        letter-spacing: 0px;
        color: var(--blue);
        text-transform: uppercase;
        margin: 0;
    }
    h2 {
        margin: 0;
        text-align: left;
        font-family: tungsten;
        font-size: 30px;
        letter-spacing: 0px;
        color: var(--blue);
        text-transform: uppercase;
        margin-bottom: 8px;
    }
    p {
        text-align: left;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0px;
        color: #000D24;
        text-transform: uppercase;
        margin: 0;
    }
    @media (max-width:768px) {
        h1 {
            font-size: 40px;
        }
    }
    @media (max-width:576px) {
        h1 {
            font-size: 35px;
        }
        h2 {
            font-size: 25px;
        }
    }
`;

const SectionTitle = styled.h1`
    text-align: center;
    font-size: 60px;
    font-family: tungsten;
    letter-spacing: 0px;
    color: var(--blue);
    position: relative;
    margin-bottom: 25px;
    &:after {
        content: "";
        position: absolute;
        width: 182px;
        height: 3px;
        background-color: var(--yellow);
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
    }
    @media (max-width:768px) {
        font-size: 40px;
        &:after {
            width: 140px;
        }
    }
`;

const SectionDesc = styled.p`
    text-align: center;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0px;
    color: #000D24;
    margin-bottom: 40px;
`;

const Heading = styled.h1`
    font-weight: 900;
    font-size: 50px;
    letter-spacing: 0px;
    color: var(--blue);
    margin: 0 0 10px;
    @media (max-width:992px) {
        font-size: 35px;
    }
    @media (max-width:576px) {
        font-size: 30px;
    }
`;

const Heading2 = styled.h2`
    font-weight: 900;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: 0px;
    color: var(--blue);
    margin: 0 0 13px;
`;

const Description = styled.p`
    font-size: 14px;
    letter-spacing: 0px;
    color: #000D24;
    font-weight: 500;
    margin-bottom: 13px;
`;

const LearnMore = styled.button`
    width: 230px;
    height: 45px;
    background: var(--yellow);
    border-radius: 23px;
    border: none;
    font-size: 14px;
    letter-spacing: 0px;
    color: #FEFEFE;
    transition: 0.2s all;
    &.hover {
        background-color: transparent;
        border: 1px solid var(--yellow);
        color: var(--yellow);
        &:hover {
            background-color: var(--yellow);
            color: white;
        }
    }
    &:hover {
        background-color: rgb(242,177,56,0.9);
        transform: scale(1.03);
    }
`;

const BgGrey = styled.div`
    background-color: #FCFCFC;
`;

const BgBlue = styled.div`
    background-color: #F8FAFF;
`;

const ListCard = styled.div`
    min-height: 368px;
    background-color: #FFFFFF;
    border: 1px solid #F1F1F1;
    border-radius: 15px;
    padding: 20px 15px;
    margin-bottom: 30px;
    h1 {
        text-align: left;
        font-size: 18px;
        line-height: 22px;
        letter-spacing: 0px;
        color: var(--yellow);
    }
    h2 {
        text-align: left;
        line-height: 19px;
        font-size: 16px;
        letter-spacing: 0px;
        color: #042154;
        margin-bottom: 5px;
    }
    p {
        color: #000D24;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 10px;
    }
`;

const DescriptionContainer = styled.div`
    background-color: #FEFEFE;
    box-shadow: 0px 0px 10px #1D1D1D1A;
    border-radius: 30px;
    padding: 30px 35px;
    margin-bottom: 66px;
    h1 {
        font-size: 34px;
        margin-bottom: 25px;
        margin-top: 5px;
    }
    h6 {
        color: #707070;
        margin-bottom: 4px;
        font-size: 17px;
        font-weight: 600;
    }
    p {
        color: #707070;
        margin-bottom: 15px;
        line-height: 18px;
        b {
            font-weight: 500;
        }
    }
    @media (max-width:768px) {
        padding: 30px 20px;
        h1 {
            font-size: 25px;
        }
    }
`;

export {
    Container,
    Banner,
    SectionTitle,
    SectionDesc,
    Heading,
    Description,
    LearnMore,
    BgGrey,
    BgBlue,
    Heading2,
    ListCard,
    DescriptionContainer,
}