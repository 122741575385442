import React, { useEffect } from 'react'
import './assets/fonts.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import GlobalStyle from "./components/GlobalStyled"
import Layout from "./routes/Layout"
import { useLocation } from "react-router-dom"
import "./components/home/our-work/services/Styled.css"

const App = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return (
    <>
      <GlobalStyle />
      <Layout />
    </>
  )
}

export default App