import { createGlobalStyle } from "styled-components"
import {
    sectionBg1,
    sectionBg2,
    greyBg,
    sectionBg3,
} from "./AllImages"

const GlobalStyle = createGlobalStyle`
:root {
    --blue: #042154;
    --yellow: #F2B138;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
html {
    scroll-behavior: smooth;
}
body {
    background-color: #FEFEFE;
    color: var(--blue);
    font-family: 'Roboto', sans-serif;
    /* font-family: 'Lato', sans-serif; */
}
a {
    text-decoration: none;
}
button {
    cursor: pointer;
}
input, textarea, select, button {
    outline: none !important;
}
.blue {
    color: var(--blue);
}
.yellow {
    color: var(--yellow);
}
.blue-bg {
    background-color: var(--blue);
}
.yellow-bg {
    background-color: var(--yellow);
}
.flex-column {
    flex-direction: column;
}
.center {
    display: flex;
    align-items: center;
    justify-content: center;
}
.between {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.end {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.flex-1 {
    flex: 1;
}
.arrow-icon {
    display: block;
    margin: 0 auto 100px auto;
}
.text-right {
    text-align: right;
}
.section-bg-1,
.section-bg-2,
.section-bg-3,
.section-bg-4 {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center right;
    padding: 104px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.section-bg-1 {
    background-image: url(${sectionBg1});
    min-height: 750px;
    position: relative;
    overflow-x: hidden;
    .circles {
        position: absolute;
        top: -48px;
        right: -190px;
        z-index: -1;
    }
    .half-circles {
        position: absolute;
        left: 0;
        bottom: -100px;
    }
}
.section-bg-2 {
    background-image: url(${sectionBg2});
    min-height: 750px;
    position: relative;
    margin-top: 50px;
    .curvy {
        position: absolute;
        top: -40px;
        right: 0;
    }
}
.section-bg-3 {
    background-image: url(${greyBg});
    min-height: 624px;
    margin-top: -128px;
    clip-path: polygon(0 0, 100% 20%, 100% 100%, 0 100%);
}
.section-bg-4 {
    background-image: url(${sectionBg3});
    background-position-x: center;
    min-height: 668px;
    margin-bottom: 40px;
    margin-top: -50px;
}
.blue-dots {
    position: absolute;
    left: -120px;
    &.right {
        left: inherit;
        right: -120px;
    }
}
.mb-60 {
    margin-bottom: 60px !important;
}
.mb-80 {
    margin-bottom: 80px;
}
.mb-200 {
    margin-bottom: 200px;
}
.mb-100 {
    margin-bottom: 100px;
}
.pt-45 {
    padding-top: 45px !important;
}
.pt-40 {
    padding-top: 40px;
}
.pb-60 {
    padding-bottom: 60px !important;
}
.pb-40 {
    padding-bottom: 40px !important;
}
.mb-50 {
    margin-bottom: 50px;
}
.mb-45 {
    margin-bottom: 45px;
}
.mb-35 {
    margin-bottom: 35px;
}
.pt-60 {
    padding-top: 60px;
}
.swiper {
    padding-bottom: 60px !important;
}
.swiper-pagination {
    bottom: 0;
}
.swiper-pagination-bullet {
    width: 12px !important;
    height: 12px !important;
    background-color: #DEDEDE !important;
    opacity: 1 !important;
}
.swiper-pagination-bullet-active {
    width: 15px !important;
    height: 15px !important;
    background-color: var(--blue) !important;
    margin-bottom: -1px !important;
}
.z-index-1 {
    z-index: -1;
}
@media (min-width:992px) {
    .text-lg-right {
        text-align: right;
    }
}
@media (max-width:992px) {
    .mb-100-lg {
        margin-bottom: 100px;
    }
}
@media (max-width:768px) {
    .arrow-icon {
        margin: 30px auto 100px auto;
    }
    .section-bg-3 {
        margin-top: -160px;
    }
    .mb-200 {
        margin-bottom: 100px;
    }
    .text-center-md {
        text-align: center;
    }
}
`;

export default GlobalStyle;