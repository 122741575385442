import styled from "styled-components"
import { Link } from "react-router-dom"
import { Row, Col } from 'react-bootstrap'
import {
    LearnMore,
    Container,
    SectionTitle,
} from '../Style'
import {
    ArrowVector,
    FlowerVector,
} from '../AllImages'

const EngageModel = ({
    classN,
    title,
    Icon,
    line1,
    line_description,
    line2,
    line3,
    line4,
    line5,
    link,
}) => {
    return (
        <EngageModelStyled className={classN}>
            <div className="head center">
                <h1>{title}</h1>
            </div>
            <div className="body">
                <Icon />
                <h2>{title}</h2>
                <p>{line_description}</p>
                <p>{line1}</p>
                <p className="mb-0">{line2}</p>
                <p>{line3}</p>
                <p>{line4}</p>
                <p>{line5}</p>
                <Link to={link}>
                    <LearnMore className="hover">Learn More</LearnMore>
                </Link>
            </div>
        </EngageModelStyled>
    )
}

const EngageModelStyled = styled.div`
    background-color: #FFFFFF;
    box-shadow: 0px 0px 6px #1A1A1A17;
    border-radius: 20px;
    margin-bottom: 40px;
    &.blue {
        .head {
            background-color: var(--blue);
        }
        .hover {
            border-color: var(--blue);
            color: var(--blue);
            &:hover {
                background-color: var(--blue);
                color: white;
            }
        }
    }
    &.yellow {
        .head {
            background-color: var(--yellow);
        }
    }
    .head {
        border-radius: 20px 20px 0px 0px;
        height: 70px;
        text-align: center;
        h1 {
            font-size: 24px;
            letter-spacing: 0px;
            color: #FEFEFE;
            margin: 0;
        }
    }
    .body {
        padding: 30px 30px 50px 30px;
        svg {
            margin-bottom: 20px;
        }
        h2 {
            margin-bottom: 15px;
            font-size: 24px;
            letter-spacing: 0px;
        }
        p {
            text-align: left;
            letter-spacing: 0px;
            font-size: 14px;
            margin-bottom: 10px;
            &:last-of-type {
                margin-bottom: 35px;
            }
        }
    }
`;
const WhoAreWe = ({
    heading,
    para1,
    para2,
    Icon,
}) => {
    return (
        <WhoAreWeStyled>
            <ArrowVector className="bg-top-vector" />
            <FlowerVector className="bg-bottom-vector" />
            <SectionTitle>{heading}</SectionTitle>
            <Container>
                <Row>
                    <Col md={6} className="mb-md-0 mb-45">
                        <p>{para1}</p>
                        <p>{para2}</p>
                        <Link to="/about-us">
                            <LearnMore className="hover mt-3">Learn More</LearnMore>
                        </Link>
                    </Col>
                    <Col md={6} className="text-center-md">
                        <Icon className="img-fluid px-md-0 px-2" />
                    </Col>
                </Row>
            </Container>
        </WhoAreWeStyled>
    )
}

const WhoAreWeStyled = styled.div`
    position: relative;
    background-color: #F0F9FF;
    padding: 60px 0 130px 0;
    margin-bottom: 60px;
    h1 {
        margin-bottom: 95px;
    }
    p{
        text-align: left;
        font-size: 14px;
        letter-spacing: 0px;
        color: #000D24;
        margin-bottom: 15px;
    }
    .bg-top-vector {
        position: absolute;
        top: 0;
        right: 0;
    }
    .bg-bottom-vector {
        position: absolute;
        bottom: 0;
        left: 0;
    }
`;

const OurClient = ({
    classN,
    heading,
    heading1,
    description,
    heading2,
    description2,
    logo1,
    logo2,
    logo3,
    logo4,
    logo5,
    logo6,
    description3,
    heading3,
    description4,
    heading4,
}) => {
    // const classN1 = "flex-row-reverse ";
    return (
        <OurClientStyled className={classN}>
            {heading && <SectionTitle>{heading}</SectionTitle>}
            <Row className="align-items-center">
                <Col lg={6} className="mb-lg-0 mb-60">
                    <div className="login_setting_div">
                        <h1>{heading1}</h1>
                        <p>{description}</p>
                    </div>
                    <div className="login_setting_div">
                        <h1>{heading2}</h1>
                        <p>{description2}</p>
                    </div>
                    <div className="login_setting_div">
                        <h1>{heading3}</h1>
                        <p>{description3}</p>
                    </div>
                    <div className="login_setting_div">
                        <h1>{heading4}</h1>
                        <p>{description4}</p>
                    </div>
                </Col>
                <Col lg={6}>
                    <Row>
                        <Col md={4} xs={6} className="first_sec">
                            <div className="client-card center">
                                <img src={logo1} alt="logo" />
                            </div>
                        </Col>
                        <Col md={4} xs={6}>
                            <div className="client-card center up">
                                <img src={logo2} alt="logo" />
                            </div>
                        </Col>
                        <Col md={4} xs={6}>
                            <div className="client-card center">
                                <img src={logo3} alt="logo" />
                            </div>
                        </Col>
                        <Col md={4} xs={6}>
                            <div className="client-card center">
                                <img src={logo4} alt="logo" />
                            </div>
                        </Col>
                        <Col md={4} xs={6}>
                            <div className="client-card center up">
                                <img src={logo5} alt="logo" />
                            </div>
                        </Col>
                        <Col md={4} xs={6}>
                            <div className="client-card center">
                                <img src={logo6} alt="logo" />
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </OurClientStyled>
    )
}

const OurClientStyled = styled.div`
    background-color: #F4F5F7;
    padding: 45px 25px 80px 25px;
    margin-bottom: 48px;
    border-radius: 25px;
    &.bg-none-style {
        background-color: transparent;
        padding: 100px 0 0 0;
    }
    &.reverse_div{
        .row{
            flex-flow: row-reverse !important;
        }
    }
    h1 {
        margin-bottom: 120px;
    }
    .row{
        div{
            .login_setting_div{
                    display: flex;
                    align-content:center;
                    border: 1px solid #dedede;
                    padding: 15px;
                    margin-bottom: 20px;
                    background-color: #fff;
                    border-radius: 10px;
                    width: 100%;
                    h5{
                        width: 100%;
                    }
                    span{
                        display: flex;
                        align-items: center;
                    }
                    p{
                        margin-bottom: 0px;
                        font-size: 14px;
                        line-height: 24px;
                        img{
                            width:24px;
                            margin-right: 10px;
                            padding:0px;
                        }
                    }
                }
            div:nth-child(2){
                img{
                    padding: 20px 30px;
                }
            }
            display: flex;
            flex-flow: row wrap;
            img{
                width: 100%;
            }
            div{
                
            }
            h1{
                margin-bottom: 15px;
            }
            .row{
                div{
                    .client-card{
                        height: 100% !important;
                        background-color: transparent; 
                        box-shadow: none;
                        border-radius: 0px; 
                        padding: 0px; 
                        margin-bottom: 0px;
                    }
                }
            }
        }
    }
    p {
        text-align: left;
        font-size: 14px;
        letter-spacing: 0px;
        color: #000D24;
        margin-bottom: 50px;
    }
    .client-card {
        background-color: #FFFFFF;
        box-shadow: 0px 1px 6px #6E6E6E29;
        border-radius: 8px;
        padding: 15px;
        height: 170px;
        margin-bottom: 25px;
        img {
            max-width: 100%;
            max-height: 100%;
        }
        &.up {
            margin-top: -74px;
        }
    }
    @media (max-width:992px) {
        h1 {
            margin-bottom: 40px;
        }
        .client-card {
            &.up {
                margin-top: 0;
            }
        }
    }
    @media (max-width:768px){
                    .login_setting_div{
                        width: 100%;
                    }
                }
`;
// const OurClientStyled = styled.div`
// /* margin-top: 75px; */
//     .row{
//         div{

//         }
//     }
// `;

const OurClientSpeak = styled.div`
    background-color: white;
    border-radius: 44px;
    padding: 60px 13%;
    @media (max-width: 992px) {
        padding: 60px 60px;
    }
    @media (max-width: 768px) {
        padding: 60px 40px;
    }
`;

export {
    EngageModel,
    WhoAreWe,
    OurClient,
    OurClientSpeak,
}