import React from 'react'
import { Row, Col } from 'react-bootstrap'
import styled from 'styled-components'
import {
    woodB,
    mobilelogo,
} from "../../../AllImages"

const Feature = ({
    heading_login,
    discription,
    heading_login1,
    discription1,
    heading_login2,
    discription2,
    heading_login3,
    discription3,
    heading_login4,
    discription4,
    heading_login5,
    discription5,
    heading_login6,
    discription6,
    heading_login7,
    discription7,
    heading_login8,
    discription8,
    heading_login9,
    discription9,
    heading_login10,
    discription10,
    heading_login11,
    discription11,
    heading_login12,
    discription12,
    heading_login13,
    discription13,
    heading_login14,
    discription14,
    classN,
    img_change,
}) => {

    return (
        <FeatureStyled>
            <Row>
                <Col md={12} className={classN}>
                    <Col md={4}>
                        <div className="login_setting_div">
                            <h5>{heading_login}</h5>
                            <p><img src={woodB} alt="img" />{discription}</p>
                        </div>
                        <div className="login_setting_div">
                            <h5>{heading_login1}</h5>
                            <p><img src={woodB} alt="img" />{discription1}</p>
                        </div>
                        <div className="login_setting_div">
                            <h5>{heading_login2}</h5>
                            <p><img src={woodB} alt="img" />{discription2}</p>
                        </div>
                        <div className="login_setting_div">
                            <h5>{heading_login3}</h5>
                            <p><img src={woodB} alt="img" />{discription3}</p>
                        </div>
                        <div className="login_setting_div">
                            <h5>{heading_login4}</h5>
                            <p><img src={woodB} alt="img" />{discription4}</p>
                        </div>
                        <div className="login_setting_div">
                            <h5>{heading_login5}</h5>
                            <p><img src={woodB} alt="img" />{discription5}</p>
                        </div>
                        <div className="login_setting_div">
                            <h5>{heading_login6}</h5>
                            <p><img src={woodB} alt="img" />{discription6}</p>
                        </div>
                    </Col>
                    <Col md={4}>
                        <img src={img_change} alt="img" />
                    </Col>
                    <Col md={4}>
                        <div className="login_setting_div">
                            <h5>{heading_login7}</h5>
                            <p><img src={woodB} alt="img" />{discription7}</p>
                        </div>
                        <div className="login_setting_div">
                            <h5>{heading_login8}</h5>
                            <p><img src={woodB} alt="img" />{discription8}</p>
                        </div>
                        <div className="login_setting_div">
                            <h5>{heading_login9}</h5>
                            <p><img src={woodB} alt="img" />{discription9}</p>
                        </div>
                        <div className="login_setting_div">
                            <h5>{heading_login10}</h5>
                            <p><img src={woodB} alt="img" />{discription10}</p>
                        </div>
                        <div className="login_setting_div">
                            <h5>{heading_login11}</h5>
                            <p><img src={woodB} alt="img" />{discription11}</p>
                        </div>
                        <div className="login_setting_div">
                            <h5>{heading_login12}</h5>
                            <p><img src={woodB} alt="img" />{discription12}</p>
                        </div>
                        <div className="login_setting_div">
                            <h5>{heading_login13}</h5>
                            <p><img src={woodB} alt="img" />{discription13}</p>
                        </div>
                        <div className="login_setting_div">
                            <h5>{heading_login14}</h5>
                            <p><img src={woodB} alt="img" />{discription14}</p>
                        </div>
                    </Col>
                </Col>
            </Row>
        </FeatureStyled>
    )
}

const FeatureStyled = styled.div`
margin-top: 75px;
    .row{
        div{
            div:nth-child(2){
                img{
                    padding: 20px 30px;
                }
            }
            display: flex;
            flex-flow: row wrap;
            img{
                width: 100%;
            }
            div{
                .login_setting_div{
                    display: flex;
                    align-content:center;
                    border: 1px solid #dedede;
                    padding: 15px;
                    margin-bottom: 20px;
                    background-color: #fff;
                    border-radius: 10px;
                    h5{
                        width: 100%;
                    }
                    span{
                        display: flex;
                        align-items: center;
                    }
                    p{
                        margin-bottom: 0px;
                        font-size: 14px;
                        line-height: 24px;
                        img{
                            width:24px;
                            margin-right: 10px;
                            padding:0px;
                        }
                    }
                }
                @media (max-width:768px){
                    .login_setting_div{
                        width: 100%;
                    }
                }
            }
        }
    }
`;

// const CenterImage = ({
// }) => {
//     return (
//         <CenterImageStyled>
//             <Row>

//             </Row>
//         </CenterImageStyled>
//     )
// }

// const CenterImageStyled = styled.div`

// `;

export { Feature }


