import React from 'react'
import Nav from '../../../UI/Nav'
import Footer from '../../../UI/Footer'
import { Row, Col, NavItem } from 'react-bootstrap'
import { OurClient } from '../../Components'
import { OurWorkComponent } from '../../../Components'
import { CenterImage, Feature } from './Components'
import overview3 from "./Item"
// import all from "../../../AllImages"

import {
    Banner,
    Container,
    SectionTitle,
    // SectionDesc,
    // Heading,
    // heading1,
    Description,
    // LearnMore,
    // BgBlue,
} from '../../../Style'


import {
    arrowDown,
    // BlueDots,
    OurWorkVector,
    // heartbeat,
    // carribean,
    // ourFamilyDollar,
    // ourGlobal,
    // ourLsu,
    // astrazeneca,
    // eVisicLogo,
    // blueBgBox,
    // eVisicThumbnail,
    // eVisicThumbnail1,
    // eVisicThumbnail2,
    // pinkBgBox,
    // purpleBgBox,
    // mblProjectDemo,
    // mblProjectDemo1,
    mobile_multi,
    // side_logo_vision,
    laptopimg,
    project1,
    project2,
    // logoPlaceholder1,
    // logoPlaceholder2,
    // logoPlaceholder3,
} from '../../../AllImages'

const ProjectOverViewFirst = ({
    heading_login,
    discription,
    heading_login1,
    discription1,
    heading_login2,
    discription2,
    heading_login3,
    discription3,
    heading_login4,
    discription4,
    heading_login5,
    discription5,
}) => {

    const overview1 = [
        {
            "classN": "bg-none-style reverse_div setting_bor",
            "headingsection": "Dynamic Recommender System",
            "description": "• The application has a dynamic recommender system that leverages personal interests, traits, and goals to showcase opportunities.",
            "headingsection1": "Map and Navigation System",
            "description1": "• The application includes an interactive map and navigation system and offers intuitive design.",
            "headingsection2": "Wide Variety of Activities",
            "description2": "• This mobile application provides a wide array of after-school activities for children and teenagers.",
            "headingsection3": "User Network",
            "description3": "• It supports its network of engaged teens with help of the integration of its internal user forum.",
        },
    ]



    return (
        <>
            <Banner>
                <Container>
                    <Nav />
                    <Row className="align-items-center">
                        <Col md={6}>
                            <h1 className="mb-2">Spearphish </h1>
                            <h1 className="mb-2">Marketing campaigns with precision targeting and budget optimization</h1>
                            <p>Maximize your social media ad spend.</p>
                        </Col>
                        <Col className="text-center-md">
                            {/* <img src={OurWorkVector} alt="vector" className="img-fluid mt-4" /> */}
                            <OurWorkVector className="img-fluid mt-4 px-md-0 px-2" />
                        </Col>
                    </Row>
                </Container>
            </Banner>
            <div className="center">
                <img src={arrowDown} alt="icon" className="arrow-icon" />
            </div>
            {/* <div className="position-relative overflow-x-hidden">
                <BlueDots className="blue-dots right" />
            </div> */}
            <Container>
                <SectionTitle>Key & Features</SectionTitle>
                {/* <Description className="text-center Key_project">The application offers the following features:</Description>
                {overview1.map(({ headingsection, description2, headingsection3, description3, headingsection2, headingsection1, description1, description, classN }) => (
                    <OurClient
                        classN={classN}
                        heading1="Dynamic Recommender System"
                        description="• The application has a dynamic recommender system that leverages personal interests, traits, and goals to showcase opportunities."
                        heading2="Map and Navigation System"
                        description2="• The application includes an interactive map and navigation system and offers intuitive design."
                        heading3="Wide Variety of Activities"
                        description3="• This mobile application provides a wide array of after-school activities for children and teenagers."
                        heading4="User Network"
                        description4="• It supports its network of engaged teens with help of the integration of its internal user forum."
                        logo1={project1}
                    />
                ))} */}
                <Row className="align-items-center reverse_div_content_1">
                    <Col md={6}>
                        <h1 className="mb-2">The Challenge </h1>
                        <p>In conjunction with worldwide digitalization, the online showcasing industry is getting to be increasingly immersed with strong competition. Indeed the foremost experienced marketers are confronting challenges with qualified lead era, focused on campaigns, and client engagement. Also, the improvement of the focus on campaigns has ended up amazingly time-consuming with all the progressed settings, innovations, and instruments that social media marketers have at their transfer.</p>
                    </Col>
                    <Col className="text-center-md">
                        <img src={project1} alt="" />
                    </Col>
                </Row>
                <Row className="align-items-center reverse_div_content">
                    <Col md={6}>
                        <h1 className="mb-2">The Vision </h1>
                        <p>The mission of Spearphish was to rearrange marketers’ social media budget administration workflows in a fair many clicks. The thought was to form a simple-to-use social media advertisements administration stage, permitting clients to form and oversee their advertisement campaigns, alter CPCs, and getting information bits of knowledge on 6 major stages at the same time. With Spearphish information cleanliness instruments, and built-in analytics, arranging your budget and re-targeting alternatives ought to be much simpler. In the interest of their vision, Spearphish required a reliable computer program advancement company with progressed showcasing ability. That’s how they found The Web Force.</p>
                    </Col>
                    <Col className="text-center-md">
                        <img src={project2} alt="" />
                    </Col>
                </Row>
            </Container>

            {/* <Container>
                <SectionTitle className='Application Key_project'>Application & Highlights</SectionTitle>
                {overview1.map(({ headingsection, description2, headingsection3, description3, headingsection2, headingsection1, description1, description, classN }) => (
                    <OurClient
                        classN={classN}
                        heading1="Remote Opportunities"
                        description="• The application features remote activities that range from coding and painting to sports instruction and chess, and a lot more!"
                        heading2="Tech/STEM Programs"
                        description2="• The app gives teens a great way to increase their working knowledge in all things science and technical."
                        heading3="Afterschool Jobs"
                        description3="• The students can even enroll for part-time jobs that can help them focus on the kind of job they ultimately want."
                        logo1={project2}
                    />
                ))}
            </Container> */}
            <Footer />
        </>
    )
}

export default ProjectOverViewFirst