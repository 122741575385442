import React from 'react'
import Nav from '../../../UI/Nav'
import Footer from '../../../UI/Footer'
import { Row, Col } from 'react-bootstrap'
import { OurClient } from '../../Components'

import {
    Banner,
    Container,
    SectionTitle,
    Description,
} from '../../../Style'


import {
    arrowDown,
    OurWorkVector,
    featuresfront,
    featuresfront1,
    featuresfront2,
    featuresfront3,
} from '../../../AllImages'

const ProjectOverViewSix = () => {

    return (

        <>

            <Banner>

                <Container>

                    <Nav />

                    <Row className="align-items-center">

                        <Col md={6}>

                            <h1 className="mb-2">factMata.</h1>

                            <h1 className="mb-2">project/client description.</h1>

                            <p>FactMata is an AI-based platform that identifies and classifies content. Advanced natural language processing learns what different types of deceptive content look like, and then detects them in the wild. To help the algorithms continually improve, FactMata uses communities including journalists, researchers and advocacy groups, to provide constant feedback to help guide our AI.</p>

                        </Col>

                        <Col className="text-center-md">

                            <OurWorkVector className="img-fluid mt-4 px-md-0 px-2" />

                        </Col>

                    </Row>

                </Container>

            </Banner>

            <div className="center">

                <img src={arrowDown} alt="icon" className="arrow-icon" />

            </div>

            <Container>

                <SectionTitle>Features</SectionTitle>

                {/* <Description className="text-center">Tata Power - Skill Development Institute App Features.</Description> */}

                <OurClient
                    classN="bg-none-style setting_bor setting_bor_1"
                    heading1="Analysis of any text to content quality"
                    description="Scores your content on nine signals, including Hate speech and Political bias."
                    logo1={featuresfront}
                />

                <OurClient
                    classN="bg-none-style reverse_div setting_bor setting_bor_1"
                    heading1="Example of post analysis"
                    description="Look at the examples of how this software works."
                    logo1={featuresfront1}
                />

                <OurClient
                    classN="bg-none-style setting_bor setting_bor_1"
                    heading1="Detailed dashboard with results"
                    description="Convenient dashboard with indicators of your text analyzing."
                    logo1={featuresfront2}
                />

                <SectionTitle>Result.</SectionTitle>


                <OurClient
                    classN="bg-none-style reverse_div setting_bor setting_bor_1"
                    heading1="Empowering everyone with a better understanding of content."
                    // description="Look at the examples of how this software works."
                    logo1={featuresfront3}
                />

            </Container>

            <Footer />

        </>

    )

}

export default ProjectOverViewSix