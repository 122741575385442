import React from 'react'
import Nav from '../../../UI/Nav'
import Footer from '../../../UI/Footer'
import { Row, Col, NavItem } from 'react-bootstrap'
import { OurClient } from '../../../home/Components'
import { OurWorkComponent } from '../../../Components'
import { CenterImage, Feature } from './Components'
import overview3 from "./Item"
// import all from "../../../AllImages"

import {
    Banner,
    Container,
    SectionTitle,
    // SectionDesc,
    // Heading,
    // heading1,
    Description,
    // LearnMore,
    // BgBlue,
} from '../../../Style'


import {
    arrowDown,
    // BlueDots,
    OurWorkVector,
    // heartbeat,
    // carribean,
    // ourFamilyDollar,
    // ourGlobal,
    // ourLsu,
    // astrazeneca,
    // eVisicLogo,
    // blueBgBox,
    // eVisicThumbnail,
    // eVisicThumbnail1,
    // eVisicThumbnail2,
    // pinkBgBox,
    // purpleBgBox,
    // mblProjectDemo,
    // mblProjectDemo1,
    mobile_multi,
    laptopimg,
    // logoPlaceholder1,
    // logoPlaceholder2,
    // logoPlaceholder3,
} from '../../../AllImages'

const ProjectOverView = ({
    heading_login,
    discription,
    heading_login1,
    discription1,
    heading_login2,
    discription2,
    heading_login3,
    discription3,
    heading_login4,
    discription4,
    heading_login5,
    discription5,
}) => {

    const overview = [
        {
            "classN": "bg-none-style",
            "headingsection": "OVERVIEW",
            "description": "Resiwood Winners' Circle is a mobile app designed to offer users a chance to earn reward points and for the team to carry out their work efficiently. Users can do online self-assessment and schedule KYC. After scanning the QR code of Astral Product, the user can earn points and later redeem the points for different gifts, transfer points to other users and redeem cash from earned points. Users can also participate in events and meetups created by team members. A team member can validate KYC details created by the user and save and manage multiple sites' project. Team members can add their shops, create events and meetups via the app, and can review the user's complaint.",
        },
        {
            "classN": "bg-none-style reverse_div",
            "headingsection": "ABOUT CLIENT",
            "description": "Astral is one of the biggest Adhesives manufactures in India. They are manufacturers of PVC pipes, adhesives, sealants, putties, and construction aids. The company stands for its products' quality and reliability in this competitive market. They have a network of more than 1800 distributors all over the country for their products. They have 3 Large scale production plants throughout India, where a total number of 1000 Full-time employees. They are backed by their visionary founder, an Indian billionaire businessman Sandeep Engineer."
        },
        {
            "classN": "bg-none-style",
            "headingsection": "CLIENT REQUIREMENT",
            "description": "Our India-based client, Astral Adhesives, manufactures various adhesives, putties, sealants, and construction aids. The client wanted to develop a mobile app to manage their users and team members with ease. Moreover, they wanted to attract their users by selling them more products, thus rewarding them gifts. The client also wanted to notify users about their offers and news to keep them updated.",
        },
    ]

    const overview1 = [
        {
            "classN": "bg-none-style",
            "headingsection": "Dashboard:",
            "description": "• Admin has a dashboard that contains different chart wise data, so it is easy to get data analysis. You can find Users, Ratio, Sale, Active users with months, Registration of users, KYC status, and redemption data in a chart manner.",
            "headingsection1": "User:",
            "description1": "• Admin can have all user's details with filtration options. Admin can export all user data with its filter. Admin can add, edit, and view user details. There is also a search feature to help in user searching.",
        },
        {
            "classN": "bg-none-style reverse_div",
            "headingsection": "Team:",
            "description": "• Admin can add, edit, and view team member details. Admin can set monthly and yearly targets for team members. Also, Admin can create active/inactive members anytime.<br/> • Admin can assign member Pincode so KYC will receive from that Pincode to member.",
            "headingsection1": "KYC:",
            "description1": "• Admin can add, edit, and delete user KYC. Also, Admin can change the KYC status to accept, complete, and cancel. There are Schedule and Self type of KYC. Find filter features with exact data for KYC users.",
        },
        {
            "classN": "bg-none-style",
            "headingsection": "Moderator:",
            "description": "• Admin can create a sub-user call moderator. Moderator has limited access to modules that are already set by Admin.",
            "headingsection1": "QR Code:",
            "description1": "• Admin can create a product-wise QR code in batch. Also, Admin can have a filter feature that can use to search a particular QR code. Admin can update bulk QR code status.",
            "headingsection2": "Redeem Gift:",
            "description2": "• Admin can see user redeem gift requests. Admin can modify the status of the application: accept, on-way, and delivered. Admin can also cancel gift requests.",
        },
        {
            "classN": "bg-none-style reverse_div",
            "headingsection": "Cash Package:",
            "description": "• Admin can add, edit, view, and active/inactive cash redeem package with points.",
            "headingsection1": "Redeem Cash:",
            "description1": "• Admin can see redeem cash packages by the user. Admin can also change the status of the cash redeem to accept.",
            "headingsection2": "Product List:",
            "description2": "• Admin can add, edit, and active/inactive product details.",
        },
        {
            "classN": "bg-none-style",
            "headingsection": "Bonus Point:",
            "description": "• Admin can add a product bonus point for all cities / selected cities and specific time duration.",
            "headingsection1": "Gift:",
            "description1": "• Admin can add, edit, view, and export gift details, which has reflected on the app side.",
            "headingsection2": "Manage Update Info:",
            "description2": "• Admin can add, edit, view, active/inactive, and export update info on the application home page.",
        },
        {
            "classN": "bg-none-style reverse_div",
            "headingsection": "Manage Offer:",
            "description": "• Admin can add, edit, view, active/inactive, and export manage to offer, shown in the application home page.",
            "headingsection1": "Gift Store:",
            "description1": "• Admin can edit and view gift center details. Admin can also have a listing of Gift stock.",
            "headingsection2": "Change Password:",
            "description2": "• Admin can own a password for security purposes.",
            "headingsection3": "Logout:",
            "description3": "• Admin can logout from the web panel.",
        },
    ]



    return (
        <>
            <Banner>
                <Container>
                    <Nav />
                    <Row className="align-items-center">
                        <Col md={6}>
                            <h1 className="mb-2">Astral Adhesives,</h1>
                            <h1 className="mb-2">- Resiwood Winners' Circle</h1>
                            <p>LOREM IPSUM IS SIMPLY DUMMY TEXT OF THE PRINTING AND TYPESETTING INDUSTRY. LOREM IPSUM HAS BEEN THE INDUSTRY'S STANDARD DUMMY TEXT EVER SINCE THE 1500S, WHEN AN UNKNOWN PRINTER TOOK A GALLEY OF TYPE AND SCRAMBLED IT TO MAKE A TYPE SPECIMEN BOOK. IT HAS SURVIVED NOT ONLY FIVE CENTURIES,</p>
                        </Col>
                        <Col className="text-center-md">
                            {/* <img src={OurWorkVector} alt="vector" className="img-fluid mt-4" /> */}
                            <OurWorkVector className="img-fluid mt-4 px-md-0 px-2" />
                        </Col>
                    </Row>
                </Container>
            </Banner>
            <div className="center">
                <img src={arrowDown} alt="icon" className="arrow-icon" />
            </div>
            {/* <div className="position-relative overflow-x-hidden">
                <BlueDots className="blue-dots right" />
            </div> */}
            <Container>
                <SectionTitle>Growing Businesses. Enterprises & Startups</SectionTitle>
                <Description className="text-center">Over 80+ organizations have trusted us to work with them.</Description>
                {overview.map(({ headingsection, description, classN }) => (
                    <>
                        <OurClient
                            classN={classN}
                            heading1={headingsection}
                            description={description}
                            logo1={mobile_multi}
                        />
                    </>
                ))}
            </Container>
            <Container>
                <SectionTitle>Features & (Customers App)</SectionTitle>


                {overview3.map((feature) => {
                    // console.log(feature, index)
                    return (
                        <>
                            <Feature {...feature}
                                heading_login="Login:"
                                discription="Users can log in by entering their phone number."
                                heading_login1="Sign up:"
                                discription1="Sign up with the necessary details like name, email, phone number, city, state, etc."
                                heading_login2="OTP Verification:"
                                discription2="Users can enter the OTP from their mobile number for validation."
                                heading_login3="Forgot Password:"
                                discription3="OTP will be sent to the user's mobile number to verify their account."
                                heading_login4="Home:"
                                discription4="On the Home page, users can see their level, points, offers, and newsletters."
                                heading_login5="Gift Store:"
                                discription5="Users can redeem their points from the gift store. The gift will be delivered to the address or can pick up from nearby gift centers by the user's choice."
                            />
                            <Feature {...feature}
                                heading_login="Add Points:"
                                discription="Users can earn points by entering the code manually or scanning QR code from products."
                                heading_login1="Passbook:"
                                discription1="Users can see their all points transaction details in the passbook."
                                heading_login2="Compass :"
                                discription2="Users can use the compass for shows direction relative to the cardinal geographic directions."
                                heading_login3="Unit Converter:"
                                discription3="The unit converter is used to convert a unit's value to another unit by area, mass, and length."
                                heading_login4="Estimate Cost Sheet:"
                                discription4="Users can make an estimation of different products and get download & share PDF to other Apps."
                                heading_login5="Product Details:"
                                discription5="Users can see the product details and see the nearest retailer on the map."
                            />
                            <Feature {...feature}
                                heading_login="Edit Product:"
                                discription="Users can edit profile data."
                                heading_login1="Notification:"
                                discription1="Users can see all notifications and redirect them to the respective screen."
                                heading_login2="Complete KYC:"
                                discription2="1) Users can schedule KYC with a team member
                                <br/>
                                2) Users can do self KYC by himself
                                <br/>
                                3) Users can get help regarding KYC via a call help desk"
                                heading_login3="My Gift:"
                                discription3="Users can see pending & delivered redeemed gift Details status.
                                <br/>
                                Users can also cancel gift."
                                heading_login4="Redeem Point For Cash:"
                                discription4="Users can select different packages to redeem cash."
                                heading_login5="Transfer Points:"
                                discription5="Users can transfer points to other users."
                            />
                            <Feature {...feature}
                                heading_login="Event:"
                                discription="Users can see small group meetups & event details which invited by team members.<br/>Users also see expired and canceled event detail."
                                heading_login1="Change Dream Gift:"
                                discription1="Users can change their dream gifts."
                                heading_login2="Feedback: "
                                discription2="Users can give feedback and suggestions."
                                heading_login3="Refer A Friend:"
                                discription3="Users can refer to other users. If referred users signup via referral code, then they get points as a reward. "
                                heading_login4="Need Help:"
                                discription4="Users can get help via call Resiwood saathi and call to help desk."
                                heading_login5="Settings:"
                                discription5="Users can edit bank details, see FAQs, terms & conditions, privacy policy, and contact us details."
                            />
                            <Feature {...feature}
                                heading_login="Change languages:"
                                discription="Users can change the app language to English or Hindi."
                                heading_login1="Logout:"
                                discription1="Users can logout from their accounts."
                                heading_login2="Login:"
                                discription2="Team members can log in by entering their mobile number."
                                heading_login3="Sign up:"
                                discription3="Account sign up from admin panel with email and password."
                                heading_login4="Forgot Password:"
                                discription4="Team members can get a password to email once they click on forgot password."
                                heading_login5="Home:"
                                discription5="1) Team members can see total members, total volume sales, full gift redemption.<br/>2) Team members can set a target and see updated info, offers, and news.<br/>3) Team members also see action and non-active member list and see their details, like points info, gift info, and event."
                            />
                            <Feature {...feature}
                                heading_login="Member Connect:"
                                discription="1) Team members can see their and other members.<br/>2) Team members can also register customers.<br/>3) Team members can add and see active, upcoming, and complete project visit details like a trial, relationship building, on-site meetup, product used, attend complain, etc."
                                heading_login1="Add Point:"
                                discription1="Team members can also add points to the customer's account by scanning the QR code or manually entering the code."
                                heading_login2="Member List:"
                                discription2="Team members can see their members list and see their details like points, gifts, and events."
                                heading_login3="Chat:"
                                discription3="Team members can chat with other team members."
                                heading_login4="Edit Product :"
                                discription4="Team members can edit profile data."
                                heading_login5="Notification :"
                                discription5="Team members can see all notifications and redirect them to the respective screen."
                            />
                            <Feature {...feature}
                                heading_login="Small Group Meet :"
                                discription="1) Team members can see their and other members.<br/>2) Team members can scan the customer's QR code for attendance purposes.<br/>3) Team members can also cancel meetup."
                                heading_login1="Register New member :"
                                discription1="Team members can register new customer by completing KYC."
                                heading_login2="Winner Circle Meet :"
                                discription2="1) Team members can create a winner circle meet. See details and add other customers to a particular shop from here.<br/>2) Team members scan the customer's QR code for Attendance purposes.<br/>3) Team members also can cancel the winner circle meet."
                                heading_login3="Shop List :"
                                discription3="Team members can add/ edit shop and see shop details."
                                heading_login4="Approve Self KYC :"
                                discription4="Team members can see the customer's self KYC request, verify and accept or reject."
                                heading_login5="KYC Schedule:"
                                discription5="Team members can see pending KYC schedule and also verify the customer's KYC from here."
                            />
                            <Feature {...feature}
                                heading_login="Gift Redeem :"
                                discription="1) Team members can see pending and completed gifts, redeemed by the customers.<br/>2) Team members can also search gift."
                                heading_login1="Settings :"
                                discription1="Users can change passwords, see FAOs, terms & conditions, privacy policy, and contact us details."
                                heading_login2="Logout :"
                                discription2="Users can logout from their accounts."
                                heading_login3="Shop List :"
                                discription3="Team members can add/ edit shop and see shop details."
                                heading_login4="Approve Self KYC :"
                                discription4="Team members can see the customer's self KYC request, verify and accept or reject."
                                heading_login5="KYC Schedule:"
                                discription5="Team members can see pending KYC schedule and also verify the customer's KYC from here."
                            />
                        </>
                    )
                })}


            </Container>
            <Container>
                <SectionTitle>Admin & Panel</SectionTitle>
                {/* <Description className="text-center">Over 80+ organizations have trusted us to work with them.</Description> */}
                {overview1.map(({ headingsection, description2, headingsection3, description3, headingsection2, headingsection1, description1, description, classN }) => (
                    <OurClient
                        classN={classN}
                        heading1={headingsection}
                        description={description}
                        heading2={headingsection1}
                        description2={description1}
                        heading3={headingsection2}
                        description3={description2}
                        heading4={headingsection3}
                        description4={description3}
                        logo1={laptopimg}
                    />
                ))}
            </Container>
            <Footer />
        </>
    )
}

export default ProjectOverView